.fx{
	transition: all .2s ease-in-out;

	&--faded{
		opacity: 0;
		transform: translateY(-30px);
	}
}

@keyframes scaling {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0px);
	}
	30% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes bounce-horizontal {
	0% {
		transform: translateX(0px);
	}
	30% {
		transform: translateX(10px);
	}
	100% {
		transform: translateX(0px);
	}
}

@keyframes fade-in {
	0% {
		transform: translateY(20px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		transform: translateY(0px);
		opacity: 1;
	}
	100% {
		transform: translateY(-20px);
		opacity: 0;
	}
}

@keyframes bg-slide {
	0% {
		background-position: 25% center;
	}
	100% {
		background-position: 125% center;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}