.footer{

	background: $color_background_dark;
	color: $color_text_light;

	&__wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 0;
		padding: 80px 100px 60px 100px;

		@include breakpoint( $breakpoint_m ) {
			flex-direction: column;
			padding: 25px;
		}

		div{
			// max-width: 400px;
			flex: 1;
			margin: 20px 40px;

			&:nth-of-type(1){
				flex: 2;
			}
			&:nth-of-type(2),
			&:nth-of-type(3){
				flex: 1;
			}
	
			h2{
				margin: 0 0 10px 0;
			}

			p{
				font-size: 16px;
				line-height: 26px;
				font-weight: 300;
			}

			a,
			button{
				display: inline-block;
				margin: 0 10px 10px 0;
				padding: 0;
				border: none;
				background: none;
				color: $color_text_light;
				cursor: pointer;
				text-decoration: none;
				text-align: left;

				&:hover,
				&.active{
					color: $color_accent;
				}
			}

			svg{
				width: 32px;
				height: 32px;
			}
		}
	}

	&__links{
		display: flex;
		flex-direction: column;

		a,
		button{
			display: inline-block;
			margin: 0 10px 10px 0;
			padding: 0;
			font-size: 20px;
			line-height: 30px;
			border: none;
			background: none;
			color: $color_text_light;
			cursor: pointer;
			text-decoration: none;
			text-align: left;

			&:hover,
			&.active{
				color: $color_accent;
			}
		}
	}

	&__logos{
		display: block;
		margin: 50px 0 0 0 !important;
		
		img{
			max-width: 200px;
		}
	}
	
	&__bottom{
		width: 100vw;
		height: 20px;
	}
}