.media{

	// position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	// width: calc( 100% - 10px );
	// min-width: 400px;

	// background: red;

	@include breakpoint( $breakpoint_s ) {
		min-width: auto;
		width: 100%;
	}


	/******* states *******/
	&--loading-true{
		.media-item__footer{
			display: none;
		}
	}



	/******* types *******/
	&--embed{
		width: 100%;
		padding: 20px;
	}

	&--audio{
		width: calc( 100% - 40px);
		padding: 20px;
	}

	&--download{
		width: fit-content;
		background: $color_background_dark;
		flex-direction: row;
		justify-content: space-between;

		a{
			width: auto;
			text-decoration: none;
			
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			&:hover{
				.media-item__icon{
					transform: translateY(5px);
				}
			}

			.media-item__footer{
				small{
					background: none;
					color: $color_text_light;
					width: auto;
					word-break: keep-all;
				}
			}

			.media-item__icon{
				margin: 0 0 0 20px;
				transition: all .2s ease-in-out;

				svg{
					color: $color_text_light;
					width: 32px;
					height: 32px;
				}
				
			}
		}
	}

	&--lightbox{
		width: 100%;
		display: block;

		.rpv-default-layout__main{
			height: 80vh !important;
		}
	}


	/******* wrappers *******/

	&-item{
		width: 100%;

		&__footer{
			width: 100%;
			display: flex;
			align-content: center;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;

			small{
				order: 1;
				flex: 3;
				width: calc( 100% - 40px );
				display: block;
				margin: 0;
				padding: 10px 20px;
				font-size: 13px;
				font-weight: 400;
				line-height: 20px;
				background: darken( $color_background, 5% );
				color: $color_text;
				word-break: break-all;

				&:empty{
					display: none;
				}
		
				@include breakpoint( $breakpoint_m ) {
					// width: calc( 100% - 40px );
					// margin: 0 0px;
				}
			}
		
			[data-lightbox]{
				order: 2;
				display: block;
				// width: 0px;
				text-align: center;
				padding: 5px 10px;
				line-height: 22px;
				background: $color_background;
				color: $color_text;
				border-left: 1px solid #ccc;
				cursor: pointer;
				transition: all .1s ease-in-out;

				&:hover{
					background: $color_background_dark;
					color: $color_text_light;
				}

				svg{
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	

	/******* elements *******/

	audio{
		width: 100%;
		min-width: 200px;
		height: 100px;
	}
	video{
		width: 100%;
		min-width: 300px;
	}
	img{
		width: 100%;
	}
	iframe{
		width: 100%;
		min-height: 400px;

		@include breakpoint( $breakpoint_s ) {
			min-height: 300px;
		}
	}

	&__loader{

		animation-name: rotate;
		animation-duration: 1s;
		animation-play-state: running;
		animation-iteration-count: infinite;
		transform-origin: center center;

		&--done{
			display: none;
		}
	}

}