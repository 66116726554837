.gallery{

	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: center;

	&__item{
		cursor: pointer;
		transition: all .1s ease-in-out;

		margin: 20px;
		padding: 20px;
		width: calc( 25% - 80px );

		display: flex;
		justify-content: space-between;

		background: darken( $color_background, 3% );

		@include breakpoint( $breakpoint_xl ) {
			width: calc( 33% - 80px );
		}

		@include breakpoint( $breakpoint_l ) {
			width: calc( 50% - 80px );
		}

		@include breakpoint( $breakpoint_m ) {
			margin: 20px 20px 20px 20px;
			width: calc( 100% - 40px );
		}

		&:hover{
			transform: scale(1.05);
		}


		h2{
			min-width: 7ch;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			margin: 0 40px 0 10px;
			padding: 0 20px 10px 20px;

			font-size: 30px;
			font-weight: 400;
			word-break: normal;

			@include breakpoint( $breakpoint_m ) {
				font-size: 25px;
				margin: 0 20px 0 0px;
			}

			small{
				margin: 0 0 0 5px;
				font-size: 12px;
			}
		}
		img{
			width: 100%;
		}
	}

}