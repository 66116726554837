.page-transition{
	position: fixed;
	z-index: 1001;
	left: 0;
	width: 100vw;
	height: 0vh;
	opacity: 1;
}

.main{

	&.transition{
		.page-transition{
			transition: all .45s ease-in-out;
		}
		&--start{
			.page-transition{
				background: $color_background;
			}
		}
		&--mid{
			.page-transition{
				background: $color_background_accent;
			}
		}
		&--end{
			.page-transition{
				background: $color_background_dark;
			}
		}
		.menu-toggler{
			opacity: 0;
		}
	}

	&.transition--content-out{
		.page,
		.footer{
			opacity: 0;
		}
	}

	&.transition--content-in{
		.page{
			transition: all .3s ease-in-out;
		}
		.footer{
			transition: all .3s ease-in-out;
		}
	}

	&.transition--start{
		.page-transition{
			top: 0;
			width: 100vw;
			height: 100vh;
		}
	}
	&.transition--mid{
		.page-transition{
			top: 0;
			width: 100vw;
			height: 100vh;
		}
	}
	&.transition--end{
		.page-transition{
			transition: all .45s ease-in-out;
			// background: #ddd;
			bottom: 0;
			top: auto;
			width: 100vw;
			height: 0vh;
		}
	}

	
}