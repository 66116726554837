.slider{

	position: relative;
	width: 100vw;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;

	@include breakpoint( $breakpoint_m ) {
		height: auto;
	}

	/*** states ***/

	&--interacted-no{
		/*
		&:after{
			position: absolute;
			top: calc( 50% - 50px);
			right: 20px;
			display: block;
			content: '>';
			font-size: 100px;
			line-height: 100px;
			font-weight: 100;

			animation-name: bounce-horizontal;
			animation-duration: 1s;
			animation-play-state: running;
			animation-iteration-count: infinite;
			transform-origin: center center;
		}
		*/
	}
	&--interacted-yes{

	}


	/*** elements ***/

	&__container{
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 1fr;
		grid-gap: 1px;

		width: 100%;
		height: 100%;
		overflow-x: scroll;

		& > div{
			padding-right: 0vw;

			&:last-of-type{
				padding-right: 80vw;

				@include breakpoint( $breakpoint_l ) {
					padding-right: 50vw;
				}
				@include breakpoint( $breakpoint_m ) {
					padding-right: 30vw;
				}
			}
		}

		&:hover{
			// cursor: url(#{$path_assets}img/icon_drag.png), grab !important;
		}

		-ms-overflow-style: none;
		  scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}

		&-navigation{
			width: calc( 100vw - 20px );
			margin: 0 10px 5px 10px;

			@include breakpoint( $breakpoint_m ) {
				// display: none;
			}

			display: flex;
			justify-content: space-around;

			span{
				position: relative;
				display: inline-block;
				width: 100%;
				height: 15px;
				margin: 0;
				padding: 0;
				border: none;
				background: none;

				transition: all .2s ease-in-out;

				cursor: pointer;

				&:before{
					content: '';
					position: absolute;
					top: 50%;
					display: block;
					width: 100%;
					height: 2px;
					background: darken( $color_background, 5% );
				}

				&:hover,
				&.active{
					&:before{
						background: $color_navigation;
					}
				}
				
			}
		}

		&-prevnext{
			margin: 40px 0 70px 0;

			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 100%;

			@include breakpoint( $breakpoint_m ) {
				margin: 30px 0 35px 0;
			}

			&-prev,
			&-next{
				display: flex;
				justify-content: center;
				align-items: center;

				margin: 0 10px;
				padding: 10px;
				background: none;
				border: none;
				cursor: pointer;

				border: 2px solid $color_navigation;
				// border-radius: 50%;

				svg{
					width: 20px;
					height: 20px;
					color: $color_navigation;
					pointer-events: none;
					transition: all .1s ease-in-out;
				}

				&:hover{
					svg{
						// color: $color_action;
					}	
				}

				&.disabled{
					pointer-events: none;
					border-color: rgba( $color_navigation, .25 );

					svg{
						color: rgba( $color_navigation, .25 );
					}
				}
			}
		}
	}

	&__item{
		position: relative;
		margin: 0;
		padding: 0;
		min-width: auto;

		&:hover{
			// cursor: url(#{$path_assets}img/icon_drag.png), grab !important;
		}

		&-count{
			display: inline-block;
			margin: 0;
			padding: 0px;
			font-size: 11px;
			font-weight: 400;
			color: #aaa;
		}
	}
}