.sidebar{

	position: fixed;
	z-index: 83;
	top: 0px;
	bottom: 0px;
	right: 0px;

	height: calc( 100vh - 0px );

	border: none;
	padding: 0;
	transform-origin: center;
	transition: all .2s ease-in-out;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	// background: #fff;

	&.hidden{
		z-index: -1;
	}

	&--season{

		// display: none;

		& > a:not(.active),
		& > label:not(.active){
			span{
				box-shadow: 0 0 2px rgba(0,0,0,.2);
			}
		}
	}

	@include breakpoint( $breakpoint_m ) {
		display: none;
	}

	cursor: pointer;

	& > a,
	& > label {
		display: inline-block;
		margin: 1px 25px 0 0;
		padding: 2px 0px;
		cursor: pointer;
		transition: all .45s ease-in-out;
		opacity: 1;
		border-radius: 4px;
		text-decoration: none;

		span{
			display: block;
			// width: 10px;
			//height: 3px;
			color: $color_text;
			font-size: 10px;
			font-weight: 700;
			transition: all .1s ease-in-out;
			
		}

		&.hidden{
			opacity: 0;
		}

		&.active,
		&:hover{
			span{
				color: $color_action;
			}
		}
	}


}