.season{
	margin: 0px 0;
	width: 25vw;
	height: 33vh;
	background-color: #eee;
	background-size: cover;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	div{
		margin: 0px;
		padding: 5px 15px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		a{
			color: $color_text_light;
			text-decoration: none;
		}
		h1{
			margin: 0px;
			padding: 0px;
			font-size: 14px;

			&:empty{
				display: none;
			}
			
		}
		h3{
			margin: 0px;
			padding: 0px;
			font-size: 14px;

			p{
				margin: 0px;
				padding: 0px;
			}

			&:empty{
				display: none;
			}
		}
	}

	h6{
		display: inline-block;
		margin: 0px;
		padding: 10px;
		font-size: 14px;
		color: $color_text_light;
		text-shadow: 2px 2px 2px $color_background_dark;
		text-align: center;

		&:empty{
			display: none;
		}
	}
}