.menu{
	display: flex;
	flex-direction: row;
	position: fixed;
	top: 0px;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	transition: all .25s ease-in-out;

	&--visible{
		right: 0px;
		opacity: 1;
		z-index: 201;
	}
	&--invisible{
		opacity: 0;
		z-index: -1;
		right: -20px;
	}

	&__wrapper{
		width: calc( 100vw - 300px );
		margin: 0px;
		padding: 20px 150px;
		
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: center;

		background: $color_background;

		@include breakpoint( $breakpoint_xl ) {
			width: calc( 100vw - 80px );
			padding: 20px 40px;
		}

		@include breakpoint( $breakpoint_m ) {
			width: calc( 100vw - 50px );
			padding: 20px 25px;
		}

		@include breakpoint( $breakpoint_s ) {
			width: calc( 100vw - 20px );
			padding: 20px 10px;
		}
	}

	&__items{
		margin: 40px;
		height: 100%;

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: flex-start;

		overflow-y: auto;
		overflow-x: hidden;

		@include breakpoint( $breakpoint_m ) {
			margin: 20px;
		}

		@include breakpoint( $breakpoint_s ) {
			margin: 20px;
		}

		.menu__item{
			display: inline-block;
			padding: 0;

			background: none;
			border: none;
			font-family: $font_family_serif;

			font-size: 50px;
			line-height: 65px;
			font-weight: 400;

			color: $color_text;

			cursor: pointer;

			transition: all .25s ease-in-out;

			@include breakpoint( $breakpoint_xl ) {
				font-size: 40px;
				line-height: 50px;
			}

			@include breakpoint( $breakpoint_l ) {
				font-size: 35px;
				line-height: 45px;
			}

			@include breakpoint( $breakpoint_m ) {
			}

			@include breakpoint( $breakpoint_s ) {
			}

			@include breakpoint-h( '1081px' ) {
				font-size: 35px;
				line-height: 45px;
			}
		}
	}
	&__item{
		text-decoration: none;

		&.active,
		&:hover{
			color: $color_action;
		}

		&--page{
			margin: 10px 0 0 0;
			text-decoration: none !important;
		}

		&--language{
			display: none !important;
			margin: 10px 20px 0 0;
			text-transform: capitalize;
		}
		
		&--toggler{
			margin: 0 0 0 10px;
			font-size: 20px !important;

			display: none !important;

			@include breakpoint( $breakpoint_m ) {
				display: inline-block !important;
			}
		}
	}

	&__seasons{
		background: darken( $color_background, 6%);
		position: absolute;
		top: 0;
		margin: 0;
		transition: all .2s ease-in-out;

		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;

		overflow-y: auto;

		&--visible{
			right: 0;
		}
		&--hidden{
			right: 0;

			@include breakpoint( $breakpoint_m ) {
				right: 0vw;
			}
		}

		width: 25vw;
		height: 30vh;
		padding: 35vh 5vw;

		@include breakpoint( $breakpoint_xl ) {
			width: 33vw;
			height: 50vh;
			padding: 25vh 5vw;
		}
		@include breakpoint( $breakpoint_l ) {
			width: 40vw;
			height: 36vh;
			padding: 32vh 5vw;
		}
		@include breakpoint( $breakpoint_m ) {
			width: 20vw;
			height: 80vh;
			padding: 10vh 10vw;
			flex-wrap: nowrap;
		}
		@include breakpoint( $breakpoint_s ) {
			width: 20vw;
			height: 80vh;
			padding: 10vh 5vw;
			flex-wrap: nowrap;
		}
	}
	&__season{
		display: inline-block;
		// min-width: 110px;
		text-align: center;
		margin: 10px 5px;
		padding: 0 0px;
		background: none;
		text-transform: uppercase;
		font-weight: 400;
		color: $color_text;
		cursor: pointer;
		transition: all .15s ease-in-out;
		text-decoration: none !important;
		border: none;

		font-size: 16px;
		line-height: 22px;

		&.active,
		&:hover{
			color: $color_action;
		}

		@include breakpoint( $breakpoint_l ) {

		}

		@include breakpoint( $breakpoint_m ) {
		}

		@include breakpoint( $breakpoint_s ) {
			// margin: 5px;
			font-size: 16px;
		}
	}

	
	

}

.menu-toggler{

	position: fixed;
	z-index: 202;
	top: 0px;
	right: 0px;

	border: none;
	padding: 0 5px 0 5px;
	transform-origin: center;
	transition: all .2s ease-in-out;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	.menu-toggler__wrapper{
		display: inline-block;
		transition: all .1s ease-in-out;
		// font-family: sans-serif;
		font-size: 60px;
		line-height: 10px;
	}


	&--opened{
		// line-height: 16px;
		color: $color_action;
		background: transparent;

		.menu-toggler__wrapper{
			//transform: rotate(90deg);
		}
	}
	&--closed{
		color: #fff;
		background: $color_action;
		
		.menu-toggler__wrapper{
			//transform: rotate(0deg);
		}
	}
}


div[data-slug="tv"]{
	.menu-toggler{
		display: none;
		@include breakpoint( $breakpoint_l ) {
			display: block;
		}
	}
}