.lightbox{

	position: fixed;
	z-index: 990;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	// display: flex;

	&__overlay{
		position: absolute;
		z-index: 991;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.95);
	}


	&__head{
		position: absolute;
		z-index: 993;
		top: 0;
		width: 100%;
		
		display: flex;
		justify-content: space-between;
		align-items: center;

		font-size: 20px;

		color: #fff;
		background: rgba(0,0,0,.95);

		@include breakpoint( $breakpoint_m ) {
			font-size: 12px;
		}

		&-left{
			flex: 1;
			margin: 0 10px;
			text-align: left;

			@include breakpoint( $breakpoint_m ) {
				margin: 5px 10px;
				flex: 5;
			}
		}

		&-center{
			flex: 1;
			display: inline-block;
			min-width: 30px;
			text-align: center;
		}

		&-right{
			flex: 1;
			text-align: right;
			
			button{
				border: none;
				background: none;
				color: #fff;
				cursor: pointer;

				svg{
					width: 32px;
					height: 32px;
					display: inline-block;
				}
			}
		}
	}


	
	&__navigation{
		&-prev,
		&-next{
			position: absolute;
			z-index: 995;
			top: calc( 50% - 15px);
			padding: 5px;
			background: none;
			border: none;
			color: #fff;
			background: $color_action;
			cursor: pointer;
			transition: all .1s ease-in-out;

			svg{
				width: 30px;
				height: 30px;
				display: block;
			}
		}

		&-prev{
			left: 10px;
			&:hover{
				transform: translateX(-10px);
			}
		}
		&-next{
			right: 10px;
			&:hover{
				transform: translateX(10px);
			}
		}
	}


	&__content,
	&__content-copy{
		position: absolute;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		img{
			max-width: 75vw;
			max-height: 75vh;
		}
	}

	&__content{
		z-index: 992;
		transition: all .1s ease-in-out;
		text-align: center;

		.lightbox-magnifier{
			.magnifier-image{
				&:not([src]){
					display: none;
				}
			}
			.magnifying-glass{
				background-color: $color_action;
			}
		}

		&.fade{
			opacity: 0;

			&--left{
				transform: translateX(-20px);
			}
			&--right{
				transform: translateX(20px);
			}
		}

		img{
			//max-width: 50vw;
			border: none;
		}
	}

	&__content-copy{
		z-index: 991;
		transition: all .1s ease-in-out;
		pointer-events: none;

		&.fade{
			opacity: 0;
			
			&--left{
				transform: translateX(10px);
			}
			&--right{
				transform: translateX(-10px);
			}
		}
	}


	&__foot{
		position: absolute;
		z-index: 993;
		bottom: 0;
		width: 100%;
		
		display: flex;
		justify-content: space-between;
		align-items: center;

		padding: 10px;

		color: #fff;
		background: rgba(0,0,0,.95);

		@include breakpoint( $breakpoint_m ) {
			top: auto;
			bottom: 0;
			font-size: 12px;
		}

		&-left{
			flex: 1;
			text-align: left;
		}

		&-center{
			flex: 1;
			display: flex;
			justify-content: center;
    		align-items: center;
		}

		&-right{
			flex: 1;
			text-align: right;
		}

		label{
			margin: 0 5px;
			font-size: 14px;
		}

		input[type="range"]{
			width: 100px;
			height: 5px;

			-webkit-appearance: none;
			margin-right: 15px;
			background: rgba(255, 255, 255, 0.6);
			border-radius: 5px;
			background-image: linear-gradient($color_action, lighten($color_action, 0%));
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}

		input[type="range"]::-webkit-slider-thumb {
			-webkit-appearance: none;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background: $color_action;
			cursor: ew-resize;
			box-shadow: 0 0 2px 0 #555;
			transition: background .3s ease-in-out;
		  }
		  
		  input[type=range]::-webkit-slider-runnable-track  {
			-webkit-appearance: none;
			box-shadow: none;
			border: none;
			background: transparent;
		  }
	}
}