// fonts
$font_family_head: 'Space Mono', mono;
$font_family_text: 'Jost', sans-serif;
$font_family_numbers: 'Poiret One', serif;
$font_family_serif: 'Cormorant Garamond', serif;

// colors
$color_background: #EFEFE9;
$color_background_accent: #00A8A9;
$color_background_dark: #161c2f; 

$color_action: #FF5600;
$color_accent: #F68920;
$color_text: #030F35;
$color_text_light: #EFEFE9;

$color_navigation: #030F35;

$color_item_gradient: linear-gradient(
    135deg, 
    rgba(darken($color_background,2%),1) 
    50%, 
    rgba(darken($color_background,4%),1) 
100%);

// fonts
$font_size_xxl:   70px;
$font_size_xl:    50px;
$font_size_l:     30px;
$font_size_lm:    25px;
$font_size_m:     20px;
$font_size_s:     15px;
$font_size_xs:    12px;

$line_height_l: 46px;
$line_height_m: 40px;
$line_height_ms: 34px;
$line_height_s: 26px;

$grid_width: 1200px;
$panel_margin: 0;
$panel_padding: 100px 20px;

// breakpoints
$breakpoint_s: 500px;
$breakpoint_m: 767px;
$breakpoint_l: 1020px;
$breakpoint_xl: 1450px;

// paths
$path_assets: 'https://archivetest.eurokaz.hr/assets/';
// $path_assets: 'http://localhost:3000/assets/';