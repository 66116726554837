@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap');

html,
body{
	scroll-behavior: smooth;
	// overscroll-behavior: contain;
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
	  &::-webkit-scrollbar {
		display: none;
	}
}

body{
	
	width: 100vw;
	padding: 0;
	margin: 0;

	overflow-x: hidden;
	font-family: $font_family_text;

	&.is-home{
		height: 100vh;
		overflow-y: hidden;
	}

	&.is-modal{
		height: 100vh;
		overflow-y: hidden;
	}
}

.main{
	overflow: hidden;
	background: $color_background;
}

button{
	font-family: $font_family_text;
}