.search{

    &-head{
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin: 50px;

        @include breakpoint( $breakpoint_m ) {
            flex-direction: column;
        }

        h1{
            margin: 0 0 0 20px;
            color: $color_text;

            @include breakpoint( $breakpoint_m ) {
                margin: 0;
            }
        }
    }

    &__form{
        margin: 0 0 0 100px;

        @include breakpoint( $breakpoint_m ) {
            margin: 0;
        }
        
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;

        &.active{
            .btn--reset{
                display: flex;
            }
            input{
                min-width: 238px;
            }
        }

        input{
            min-width: 300px;
            padding: 10px 15px;
            border: none;
            background: #fff;
            color: $color_text;
            font-size: 16px;
            outline: none;
    
            &::placeholder{
                font-size: 13px;
                font-style: italic;
                color: rgba( $color_text, .5 );
            }
    
            @include breakpoint( $breakpoint_m ) {
                min-width: auto;
                width: 100%;
                // margin: 20px 0 0 0;
            }
        }
    
        button{
            margin: 0 0 0 0px;
            padding: 10px 25px;
            border: none;
            background: $color_action;
            color: $color_text_light;
            font-size: 16px;
    
            cursor: pointer;
    
            &.btn--search{
                background: $color_action;
            }
            &.btn--reset{
                display: none;
                background: $color_text;
                align-items: center;
                padding: 10px 15px;

                svg{
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

	
}

.search-results{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 50vw;
	padding: 0;
    margin: 30px auto 30px auto;

	@include breakpoint( $breakpoint_m ) {
		flex-direction: column;
		width: calc( 100vw - 20px);
		padding: 0 10px;
	}

    &__count{
        margin: 30px 0 50px 0;
        padding: 0 15px;
        font-size: 30px;

        display: flex;
        justify-content: space-between;
        width: calc( 100% - 30px);

        div:nth-of-type(1){
            width: 45%;
            text-align: left;
        }
        div:nth-of-type(2){
            width: 45%;
            text-align: left;
            color: darken($color_text_light, 20%);
        }
    }

	&__item{
		position: relative;
		margin: 10px 0;
		padding: 25px 15px 35px 15px;
		text-decoration: none;
		color: $color_text;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;

        width: calc( 100% - 30px );

		transition: all .2s ease-in-out;

        border-bottom: 1px solid darken($color_background, 5%);

        &:last-of-type{
            border-bottom: none;
        }

        mark{
            padding: 0px 0px;
            background-color: $color_text;
            color: $color_text_light;
            // border-bottom: 5px solid $color_action;
        }

        & > div:nth-of-type(1){
            width: 50%;
            margin-top: -20px;
        }
        & > div:nth-of-type(2){
            margin: 0 0 0 5%;
            width: 45%;
        }

        &-article{
            margin: 0 0 10px 0;
            padding: 0 0 10px 0;
            border-bottom: 1px dashed darken($color_background, 5%);

            &:last-of-type{
                border-bottom: none;
            }
        }

        small{
            font-size: 11px;
            font-weight: 400;
            color: #aaa;
        }

        h2{
            margin: 0 0 0px 0;
            text-transform: capitalize;
            font-size: 30px;
            line-height: 40px;
        }
        h3{
            margin: 0;
            font-weight: 400;
            text-transform: uppercase;
        }
        h4{
            margin: 0 0 10px 0;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }
        h5{
            margin: 0 0 10px 0;
            font-size: 16px;
            font-weight: 700;
            text-transform: capitalize;
        }
        a{
            display: inline-block;
            margin: 20px 0 0 0;
            padding: 10px 20px;
            font-size: 16px;
            background: $color_action;
            color: $color_text_light;
            text-decoration: none;
            text-transform: capitalize;
        }

        p{
            margin: 0;
            font-size: 14px;
            line-height: 22px;
        }

	}
}