.modal-season{
    position: fixed;
    z-index: 9999994;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color_background;

    &__wrapper{
        // position: relative;
        margin: 50px;
        padding: 20px 40px;
        height: calc(100vh - 140px);
        overflow-y: auto;
        background: #eee;

        @include breakpoint( $breakpoint_m ) {
            margin: 30px 10px;
            padding: 40px 20px;
            height: calc(100vh - 100px);
        }
    }

    .btn{
        position: absolute;
        background: $color_action;
        border: none;
        color: $color_text_light;
        margin: 0;
        padding: 10px 25px;
        font-size: 16px;
        outline: none;
        transition: all .1s ease-in-out;
        cursor: pointer;

        &:hover{
            background: darken( $color_action, 5% );
        }

        &--close{
            top: 0;
            right: 0;
        }
    }

    .media__loader{
        width: 50px;
        height: 50px;
        path{
            fill: #222;
        }
    }
}