.gotoseason{

	position: relative;

	height: 60vh;
	margin: 50px;
	padding: 50px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	@include breakpoint( $breakpoint_m ) {
		flex-direction: column;
		padding: 20px 0;
		height: auto;
		align-items: center;
	}

	@include breakpoint( $breakpoint_s ) {
		margin: 0;
		padding: 0;
		height: auto;
	}

	& > a > div{
		width: 200px;
		text-transform: uppercase;
	}

	a{
		width: 40vw;
		padding: 50px; 

		text-decoration: none;
		color: $color_text;
		
		background: $color_background;
    	background: $color_item_gradient;

		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;

		// cursor: url(#{$path_assets}img/arrow_right.png), e-resize;

		@include breakpoint( $breakpoint_l ) {
			flex-direction: column;
			width: 50vw;
		}

		@include breakpoint( $breakpoint_m ) {
			width: 60vw;
		}

		@include breakpoint( $breakpoint_s ) {
			width: 80vw;
		}

		&:hover{
			.media-item{
				transform: scale(1.05,1.05);
			}
		}

		h2{
			margin: 0;
			padding: 10px 0;
			font-size: 40px;
			font-weight: 400;
			font-family: "Poiret One", serif;
		}

		h3{
			margin: 60px 0 0 0px;
			text-transform: uppercase;
			font-family: $font_family_text;
		}

		h4{
			margin: 0 0 40px 0;
			font-size: 18px;
			font-weight: 400;
			font-family: $font_family_serif;
		}

		p{
			display: none;
		}

		.media{
			margin: 0 20px;
			max-width: 250px;
			overflow: hidden;

			@include breakpoint( $breakpoint_m ) {
				margin: 0;
			}

			.media-item{
				height: 100%;
				width: 100%;
				object-fit: cover;
				transition: all .5s ease-in-out;
			}

			small{
				display: none;
			}
		}
	}

	.hometripple__content-items-media{
		width: 200px;
		height: 200px;
	}
}