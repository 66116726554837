#bookmark {
	cursor: pointer;
	position: fixed;
	z-index: 9999;
	display: none;
	background: #ff0;
	color: $color_text;
	font-weight: 700;
	font-size: 12px;

	&.active{
		padding: 10px;
		display: block;
	}

	&-btns{
		position: fixed;
		z-index: 99991;
		top: 10px;
		left: 10px;
		

		button{
			margin: 2px;
			background: $color_background_accent;
			color: $color_text_light;
			font-weight: 700;
			font-size: 12px;
			border: none;
			cursor: pointer;

			&:hover{
				background: $color_action;
			}
		}
	}
}

bookmark{
	background: $color_background_accent;
	color: $color_text_light;
}