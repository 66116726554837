.section{

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	margin: 25vh 0;
	padding: 20px 0;

	@include breakpoint( $breakpoint_l ) {
		margin: 75px 0;
	}
	@include breakpoint( $breakpoint_m ) {
		flex-direction: column;
	}

	&:first-of-type{
		
	}

	&:last-of-type{
		border-bottom: none;
	}




	/*** display types */

	&--horizontal{

		margin: 0;
		padding: 0;

		.section__horizontal-items{
			width: 100%;
			height: 100%;
			overflow: hidden;

			@include breakpoint( $breakpoint_m ) {
				height: auto;
			}

			&-container{
				display: grid;
				grid-auto-flow: column;
				grid-auto-columns: 1fr;
				grid-gap: 1px;

				width: 100%;
				height: 100%;
				overflow-x: scroll;

				// &.pressed{
					.items-count{
						position: relative;
						display: inline-block;
						margin: 20px 0;
						padding: 0;
						top: 0;
						font-weight: 700;
					}
				// }

				&:hover{
					// cursor: url(#{$path_assets}img/icon_drag.png), grab !important;
				}

				-ms-overflow-style: none;
  				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}
			}

			.section__tab{
				position: relative;
				margin: 0;
				padding: 20px 40px;
				min-width: auto;
				//width: calc( 75vw - 80px);
				max-width: none;
				background: #eee;
				color: $color_text;
				pointer-events: none;
	
				@include breakpoint( $breakpoint_m ) {
					
				}
	
				h2{
					font-size: 24px;
				}
	
				h3{
					font-size: 20px;
				}
	
				p{
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}

	
	&--vertical_media_horizontal{
		margin: 0;
		padding: 0;

		// justify-content: flex-start;

		.section__vertical_media_horizontal-info{
			margin: 150px 0 50px 0;
			padding: 0 50px;

			h2{
				margin: 10px 0;
				font-weight: 700;
			}

			h3{
				margin: 10px 0;
				font-size: 30px;
			}

			p{
				margin: 0;
			}
		}

		.slider__item{
			position: relative;
			margin: 0;
			padding: 10px 40px;
			min-width: 25vw;
			background: #eee;

			@include breakpoint( $breakpoint_m ) {
				min-width: 75vw;
				width: calc( 90vw - 80px);
			}
		}

	}

	&--dts_grid1,
	&--head_horizontal{

		margin: 0;
		padding: 0;

		.section__head_horizontal-head{
			width: calc( 100vw - 100px );
			max-width: none;
			margin: 0 50px 0px 50px;
			text-align: center;

			h2{
				font-size: 50px;
			}
			p{
				max-width: none;
			}
		}

		.slider__item{
			position: relative;
			margin: 0 10px 0 0;
			padding: 10px 40px 40px 40px;
			background: darken( $color_background, 3% );
			
			min-width: auto;
			min-width: calc( 20vw - 0px );

			@include breakpoint( $breakpoint_xl ) {
				min-width: auto;
				width: calc( 25vw - 0px);
			}
			@include breakpoint( $breakpoint_l ) {
				min-width: auto;
				width: calc( 66vw - 0px);
			}
			@include breakpoint( $breakpoint_m ) {
				min-width: auto;
				width: calc( 70vw - 0px);
			}

			h2{
				font-size: 24px;
				line-height: 32px;
				font-weight: 700;
				margin: 0;
				padding: 0 20px;
				min-height: 110px;
				
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				text-transform: uppercase;

				span{
					display: inline-block;
					margin: 0;
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
				}
			}

			h3{
				font-size: 24px;
				line-height: 32px;
				padding: 0 20px;
    			min-height: 100px;
				display: flex;
				align-items: flex-start;
				text-transform: uppercase;
			}

			p{
				font-size: 14px;
				line-height: 22px;
				padding: 0 10px;
			}
		}
	}

	&--dts_fullwidth{
		margin-left: 0;
		margin-right: 0;

		.article{
			width: 100%;

			& > h2{
				width: 100%;
				text-align: center;
				margin: 0 0 20px 0px;
			}

			& > h3{
				width: 100%;
				max-width: none;
				text-align: center;
			}

			p{
				max-width: none;

				p{
					//padding: 0 20px;
				}
			}
		}
	}

	// gallery
	&--dtp_grid4{
		margin: 0;

		.article{

			&:first-of-type{
				margin: 0 0 100px 0;

				@include breakpoint( $breakpoint_l ) {
					margin: 0 0 40px 0;
				}

				@include breakpoint( $breakpoint_m ) {
					margin: 0 0 20px 0;
				}

				h2{
					text-align: center;
				}
				
				p{
					margin: 50px 0;
				}
			}
		}

		.gallery{
			&__item{
				.media-item__footer{
					display: none;
				}
			}
		}

		.gallery-modal{

			position: fixed;
			z-index: 9999994;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(20,20,20,.999);

			display: flex;
			flex-direction: column;

			&__wrapper{
				position: relative;
				margin: 0px;
				padding: 0px;
				width: calc( 100% - 0px );
				height: calc( 100% - 0px );

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
			}

			&__close{
				position: fixed;
				z-index: 9999995;
				right: 0px;
				top: 0px;
				
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0;
				padding: 4px 6px;
				background: $color_action;
				color: $color_text_light;
				border: none;
				cursor: pointer;
				font-size: 40px;
				line-height: 40px;

				&:hover{
					background: $color_action;
				}

				@include breakpoint( $breakpoint_m ) {
					// top: 25px;
					padding: 10px;
					font-size: 29px;
					line-height: 29px;
				}
			}

			&__head{
				width: 100%;
				height: 49px;
				margin: 0 0 0px 0;
				text-align: center;
				
				display: flex;
				justify-content: center;
				align-items: center;

				color: $color_text;
				background: $color_background;
				margin: 0px 0 0 0;
				padding: 0px;

				h2{
					margin: 0 20px 0 0;
					font-size: 30px;
					font-weight: 400;
				}
				h3{
					margin: 0 20px 0 0;
					font-size: 20px;
					max-width: none;
				}
				p, p > p{
					margin: 0 20px 0 0;
					font-size: 16px;
				}
			}

			&__media{
				height: calc( 100vh - 130px );
				overflow: hidden;

				& > div{
					height: 100%;
					display: flex;
					position: relative;
				}

				.media{
					// height: calc( 100% - 60px );

					&-item{
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
	
						&__footer{
							position: absolute;
							bottom: 0;
							width: auto;
							small{
								background-color: $color_background_dark;
								color: $color_text_light;
							}
						}
					}

					&__loader{
						width: 50px;
						height: 50px;
						path{
							fill: #fff;
						}
					}
				}
			}

			&__navi{
				

				&-btn{
					position: fixed;
					z-index: 99;
					bottom: 25px;
					padding: 10px;
					font-size: 20px;
					line-height: 20px;
					border: none;
					color: $color_background;
					background: $color_accent;
					cursor: pointer;
					transition: all .2s ease-in-out;

					@include breakpoint( $breakpoint_m ) {
						bottom: 20px;
					}

					&--prev{
						left: 0;
					}
					&--next{
						right: 0;
					}

					@include breakpoint( $breakpoint_m ) {
						padding: 10px;
						font-size: 14px;
						line-height: 14px;
					}
					
					&.disabled{
						pointer-events: none;
						opacity: .25;
					}
				}
			}

			&__thumbs{
				// height: 65px;
				width: 100%;
				background: $color_background;
				margin: 0;
				padding: 10px 0px;

				@include breakpoint( $breakpoint_m ) {
					padding: 5px 0;
				}

				&-wrapper{
					margin: 0px 50px -10px 50px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					overflow-x: auto;

					@include breakpoint( $breakpoint_m ) {
						justify-content: flex-start;
						margin: 0 50px -5px 50px;
					}

					& > div{
						border-bottom: 5px solid transparent;
						transition: all .2s ease-in-out;
	
						&:hover{
							border-bottom-color: #ccc;
						}
	
						&.active{
							border-bottom-color: $color_action;
						}
					}
				}

				.media-item{
					margin: 5px;
					width: auto;
					cursor: pointer;

					@include breakpoint( $breakpoint_m ) {
						width: 50px;
						height: 50px;
					}

					&__footer{
						display: none;
					}
				}
			}
		}
	}

	// medijske
	&--dtp_grid5{
		margin: 0;

		.article{

			&:first-of-type{
				margin: 0;

				h2{
					margin:0 0 50px 0;
					text-align: center;
				}

				p{
					margin: 50px auto;
				}
			}
		}

		.gallery{
			&__item{
				.media-item__footer{
					display: none;
				}
			}
		}
	}

	// razno
	&--dtp_grid6{
		margin: 0;

		.article{

			&:first-of-type{
				margin: 0;

				h2{
					text-align: center;
				}

				p{
					margin: 50px auto;
				}
			}
		}

		.gallery{
			&__item{
				.media-item__footer{
					display: none;
				}
			}
		}

		.gallery-modal{

			position: fixed;
			z-index: 9999994;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $color_background;
			overflow-y: auto;

			/*
			display: flex;
			flex-direction: column;
			*/

			width: 100%;

			&__wrapper{
				position: relative;
				margin: 0px;
				padding: 30px;
				width: calc( 100% - 60px );
				// height: calc( 100% - 60px );

				/*
				display: flex;
				flex-direction: column;
				align-items: center;
				// justify-content: space-between;
				*/
			}

			&__close{
				position: fixed;
				z-index: 9999995;
				right: 0px;
				top: 0px;
				
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0;
				padding: 4px 9px;
				background: $color_action;
				color: $color_text_light;
				border: none;
				cursor: pointer;
				font-size: 40px;

				&:hover{
					background: $color_action;
				}

				@include breakpoint( $breakpoint_m ) {
					top: 25px;
					padding: 10px;
					font-size: 20px;
					line-height: 20px;
				}
			}

			&__head{
				width: 100%;
				height: 50px;
				margin: 0 0 0px 0;
				text-align: center;
				
				display: flex;
				justify-content: center;
				align-items: center;

				color: $color_text;
				background: $color_background;
				margin: -30px 0 0 0;
				padding: 20px 30px;

				h2{
					margin: 0 20px 0 0;
					font-size: 50px;
					font-weight: 700;
					font-family: $font_family_serif;
				}
				h3{
					margin: 0 20px 0 0;
					font-size: 20px;
					max-width: none;
				}
				p, p > p{
					margin: 0 20px 0 0;
					font-size: 16px;
				}
			}

			.media{
				.media-item__title{
					font-size: 40px;
					font-weight: 700;
					line-height: 45px;
					margin: 120px 0 10px 0;
					text-transform: uppercase;
					white-space: nowrap;
					word-break: normal;
				}
			}
		}
	}

	&--media{

		.section__media-head{
			width: calc( 100vw - 100px );
			max-width: none;
			margin: 0 50px 0px 50px;
			text-align: center;

			h2{
				font-size: 50px;
			}
			p{
				max-width: none;
			}
		}

		.section__media-items{
			width: 100%;
			height: 75vh;
			// overflow: hidden;

			@include breakpoint( $breakpoint_m ) {
				height: 50vh;
			}

			&-container{
				display: grid;
				grid-auto-flow: column;
				grid-auto-columns: 1fr;
				grid-auto-rows: 100%;
				grid-gap: 0px;

				width: 100%;
				height: 100%;
				overflow-x: scroll;

				&.pressed{
					.items-count{
						top: 0;
					}
				}

				&:hover{
					// cursor: url(#{$path_assets}img/icon_drag.png), grab !important;
				}

				-ms-overflow-style: none;
  				scrollbar-width: none; 
				&::-webkit-scrollbar {
					display: none;
				}

				@include breakpoint( $breakpoint_m ) {
					grid-auto-columns: auto;
				}

				.media{
					height: 100%;
					width: auto;
					min-width: auto;
					margin: 0;
					
					img{
						height: 100%;
						width: auto;
					}

					small{
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}
			}

			.section__tab{
				position: relative;
				width: auto;
				max-width: none;
				margin: 0;
				pointer-events: none;
			}
		}
	}



	/*** custom classes */
	&.vimeo-embed{
		margin-bottom: 0;
		padding-bottom: 0;	
	}

	&.extra-links{
		margin: 0 0 10vh;
		width: 100vw;
		padding-top: 0;

		@include breakpoint( $breakpoint_m ) {
			margin: 0;
			padding: 0;
		}

		& > .article{
			width: calc( 100% - 100px);
			padding: 150px 50px;
			text-align: center;
			background: $color_background_accent;
			color: $color_text_light;

			@include breakpoint( $breakpoint_l ) {
				// width: 50vw;
			}
			@include breakpoint( $breakpoint_m ) {
				width: calc(100% - 20px);
				padding: 50px 10px;
			}
			@include breakpoint( $breakpoint_s ) {
				// width: 80vw;
			}

			p{
				margin: 50px 0 0 0;
				max-width: none;
			}
		}

		a{
			cursor: pointer;
			padding: 0 5px;
			font-size: 20px;
			line-height: 23px;
			font-weight: 700;
			color: $color_text_light;
			font-family: $font_family_text;
			text-transform: uppercase;
			text-decoration: none;
			transition: all .2s ease-in-out;

			@include breakpoint( $breakpoint_l ) {
				display: block;
			}
			@include breakpoint( $breakpoint_m ) {
				font-size: 20px;
			}

			&:hover{
				color: $color_text;
			}
		}
	}


	/*** elements ***/

	&__tab{
		flex: 1;
		max-width: calc( 60vw - 200px );
		margin: 0px 100px 100px 100px;

		@include breakpoint( $breakpoint_l ) {
			max-width: calc( 80vw - 200px );
			margin: 0px 50px 50px 50px;
		}
		@include breakpoint( $breakpoint_m ) {
			max-width: none;
			margin: 0px 25px 25px 25px;
		}
	}


	& > .article{
		&:first-of-type{
			h2{
				font-family: $font_family_serif;

				&:before{
					// content: '~ ';
				}
				&:after{
					// content: ' ~';
				}
			}
		}
		
		/*
		&:not(:first-of-type){
			h2{
				font-size: 30px;
				font-weight: 700;
				color: #bbb;
			}
		}
		*/
	}

	h2{
		margin: 10px 0 10px 0;
		font-size: 50px;
		

		@include breakpoint( $breakpoint_l ) {
			font-size: 40px;
		}
		@include breakpoint( $breakpoint_m ) {
			font-size: 30px;
		}
	}

	h3{
		margin: 0;
		max-width: 50vw;
		font-size: 35px;
		line-height: 44px;
		font-weight: 400;

		@include breakpoint( $breakpoint_l ) {
			font-size: 28px;
			line-height: 35px;
		}
		@include breakpoint( $breakpoint_m ) {
			max-width: 100vw;
			font-size: 22px;
			line-height: 28px;
		}
	}
	p{
		max-width: 65ch;
		font-size: 20px;
		line-height: 29px;

		@include breakpoint( $breakpoint_l ) {
			max-width: 65ch;
			font-size: 18px;
			line-height: 26px;
		}
		@include breakpoint( $breakpoint_m ) {
			max-width: 90vw;
			font-size: 16px;
			line-height: 24px;
		}
	}


	&__media{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 -100px;
		padding: 100px 0 0 0;
		// height: 80vh;

		@include breakpoint( $breakpoint_m ) {
			//flex-direction: row;
			margin: 0 -25px;
			padding: 25px 0 0 0;
		}
	}
	
}