.article{

	// width: calc( 50vw + 100px );

	@include breakpoint( $breakpoint_m ) {
		// width: calc( 100vw - 50px );
	}



	/*** variations ***/

	&--default{
		.btn{
			margin: 20px 0 0 0;
			padding: 10px 20px;
			background: $color_background_dark;
			border: none;
			color: $color_text_light;
			cursor: pointer;
		}
	}

	&--article_imgtext{

		h2{
			margin: 0;
		}
		h3{
			margin: 0;
		}
		p{
			a{
				color: $color_text;

				&:before{
					display: inline-block;
					margin: 0 5px 0 0;
					content: '> ';
				}
			}
		}
	}

	&--article_imgslider{
		
	}

	&--article_mediagrid{
		.article__media{			
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;

			.slider__item{
				margin: 20px;
				width: calc( 33.3% - 40px);

				@include breakpoint( $breakpoint_m ) {
					width: calc(100% - 40px);
				}
				
				&:nth-of-type(1){
					margin: 20px;
					width: calc( 100% - 40px);
				}
			}
		}
	}

	&--article_modal{

		&__head{
			position: relative;
		}

		.btn{
			background: $color_action;
			border: none;
			color: $color_text_light;
			margin: 0;
			padding: 10px 25px;
			font-size: 16px;
			outline: none;
			transition: all .1s ease-in-out;
			cursor: pointer;

			&:hover{
				background: darken( $color_action, 5% );
			}

			&--more{
				position: absolute;
				bottom: 0;
				right: 0;
			}
			&--close{
				position: relative;
				bottom: 0;
				right: 0;
			}
		}

		.modal{
			/*
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			*/

			display: flex;
			flex-direction: column;

			&__wrapper{
				// position: relative;
				margin: 0px;
				padding: 0;
				overflow-y: auto;
				// background: #fff;

				//height: 300px;

				@include breakpoint( $breakpoint_m ) {
					//height: calc(200px - 40px);
				}
			}
		}
	}

	&--article_modalfull{

		&__head{
			position: relative;
		}

		.btn{
			background: $color_action;
			border: none;
			color: $color_text_light;
			margin: 0;
			padding: 10px 25px;
			font-size: 16px;
			outline: none;
			transition: all .1s ease-in-out;
			cursor: pointer;

			&:hover{
				background: darken( $color_action, 5% );
			}

			&--more{
				position: absolute;
				bottom: 0;
				right: 0;
			}
			&--close{
				float: right;
				// position: relative;
				bottom: 0;
				right: 0;
			}
		}

		.modal{
			position: fixed;
			z-index: 9999994;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(255,255,255,.99);

			display: flex;
			flex-direction: column;

			&__wrapper{
				// position: relative;
				margin: 50px;
				padding: 20px 40px;
				height: calc(100vh - 40px);
				overflow-y: auto;
				background: #eee;
			}
		}
	}

	&--article_herocontent{
		//height: 90vh;
		position: relative;
		// overflow: hidden;

		@include breakpoint( $breakpoint_m ) {
			// height: 70vh;
		}

		.article__hero{
			// position: absolute;
			// z-index: 991;
			// top: 0;
			// left: 0;
			// right: 0;
			// bottom: 0;

			.media{
				// height: 100%;
				// object-fit: cover;
				// object-position: center;
			}
		}
		.article__content{
			// position: absolute;
			// bottom: 20px;
			// left: 20px;
			// z-index: 992;
			padding: 20px;
			background: #fff;
			text-align: center;

			h2{
				color: $color_background_dark !important;
			}
			.media--download{
				margin: 0 auto;
			}
		}
	}

	&--article_videofull{
		
		position: relative;
		overflow: hidden;
		padding: 50px 0;
		background: #000;
		color: $color_text_light;
		

		.article__video{
			background: #000;
			padding: 5vh 0;

			height: 65vh;

			@include breakpoint( $breakpoint_xl ) {
				height: 60vh;
			}
			@include breakpoint( $breakpoint_l ) {
				height: 60vh;
			}
			@include breakpoint( $breakpoint_m ) {
				height: 50vh;
			}
			@include breakpoint( $breakpoint_s ) {
				height: 40vh;
			}

			.media{
				height: 100%;
				padding: 0;

				.media-item{
					height: 100%;
				}

				iframe{
					height: 100%;
					object-fit: cover;
				}
			}
			
		}
		.article__content{
			h2{
				text-align: center;
				font-size: 50px;

				@include breakpoint( $breakpoint_m ) {
					font-size: 40px;
				}
				@include breakpoint( $breakpoint_s ) {
					font-size: 30px;
				}
			}	
		}
	}

	&--article_twohoriz{

		.article__wrapper{
			display: flex;
			overflow: hidden;

			@include breakpoint( $breakpoint_l ) {
				flex-direction: column;

				& > div{
					flex: 1;
				}
			}

			& > div{

				&:first-of-type{
					flex: 1;
				}
				&:last-of-type{
					flex: 1;
				}
			}

			.article__content{
				padding: 50px;
				height: calc(100% - 100px);

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				background: darken( $color_background_accent, 5% );
				color: $color_text_light;
				border-top: 1px solid darken( $color_background_accent, 5% );

				@include breakpoint( $breakpoint_l ) {
					align-items: flex-start;
				}
				
				h2{
					display: block;
					margin: 0;
					font-weight: 700;
					font-family: $font_family_serif;
					text-transform: uppercase;

					&:before{
						content: '~ ';
					}
					&:after{
						content: ' ~';
					}
				}
				& > p{
					margin: 0;
				}
				a[download]{
					background: $color_accent;
					svg{
						color: $color_text_light;
					}
					small{
						color: $color_text_light;
					}
				}
			}
			
		}
	}

	&--article_description{
		p > ul,
		p > ol{
			margin: -20px 0;
		}
	}

	&--article_textmediacolumns{

		padding: 50px !important;
		background: darken( $color_background, 3%);

		.article__text{
			width: 25vw;

			@include breakpoint( $breakpoint_xl ) {
				width: 50vw;
			}

			@include breakpoint( $breakpoint_m ) {
				width: auto;
			}

			.article__media{
				@include breakpoint( $breakpoint_m ) {
					width: auto;
					text-align: center;
				}
			}

			h2{
				margin: 0 50px 0 0;
				font-size: 40px;
				line-height: 45px;
				font-weight: 700;

				@include breakpoint( $breakpoint_m ) {
					margin: 0;
				}
			}
			p{
				font-size: 16px;
				line-height: 24px;
				padding: 0;
				display: flex;
				align-items: flex-start;

				@include breakpoint( $breakpoint_m ) {
					justify-content: center;
				}
			}
		}
	}

	&--no-thumb{
		background: transparent !important;
	}


	/*** elements ***/

	ul{
		width: 100%;
		// max-width: 50vw;
    	margin: 0 auto;
		padding: 0;
		list-style: square;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		transition: all .25s ease-in-out;

		@include breakpoint( $breakpoint_l ) {
			// max-width: 75vw;
		}

		@include breakpoint( $breakpoint_m ) {
			// max-width: 100vw;
		}
		
		
		
		li{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-content: flex-start;
			margin: 0px;
			padding: 25px 20px 25px 20px;
			width: calc(25% - 42px);
			font-size: 25px;
			line-height: 35px;
			transition: all .25s ease-in-out;

			// word-break: keep-all;
			// white-space: nowrap;
			overflow-x: auto;

			&:first-of-type{
				border-right: none;
				font-family: $font_family_serif;
				font-weight: 700;
				justify-content: center;
				width: calc(100% - 41px);

				@include breakpoint( $breakpoint_xl ) {
					// width: calc(66.6% - 41px);
				}
				@include breakpoint( $breakpoint_l ) {
					width: calc(100% - 20px);
				}
			}


			@include breakpoint( $breakpoint_xl ) {
				width: calc(33.3% - 41px);
			}
			@include breakpoint( $breakpoint_l ) {
				width: calc(50% - 41px);
			}
			@include breakpoint( $breakpoint_m ) {
				padding: 15px 10px;
				width: calc(100% - 20px);
				font-size: 18px;

				border-left: none;
				border-right: none;
			}

			strong{
				margin: 0 0 25px 0;
				font-size: 25px;

				@include breakpoint( $breakpoint_m ) {
					margin: 0 0 0px 0;
					font-size: 20px;
				}
			}

			
		}
	}

	ol{
		width: 100%;
		// max-width: 50vw;
		margin: 0 auto;
		padding: 0;
		list-style: square;

		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;

		transition: all .25s ease-in-out;

		@include breakpoint( $breakpoint_l ) {
			// max-width: 75vw;
		}

		@include breakpoint( $breakpoint_m ) {
			// max-width: 100vw;
		}
		
		li{
			width: calc( 25% - 52px );
			margin: 0px;
			padding: 25px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			font-size: 25px;
			line-height: 35px;
			transition: all .1s ease-in-out;
			word-break: keep-all;

			&:first-of-type{
				border-right: none;
				font-family: $font_family_serif;
				font-weight: 700;
				justify-content: center;
				width: calc(100% - 51px);

				@include breakpoint( $breakpoint_xl ) {
					// width: calc(66.6% - 61px);
				}
				@include breakpoint( $breakpoint_l ) {
					width: calc(100% - 20px);
				}
				@include breakpoint( $breakpoint_m ) {
					// border-color: #444;
				}
			}

			@include breakpoint( $breakpoint_xl ) {
				padding: 30px;
				width: calc(33.3% - 61px);
			}

			@include breakpoint( $breakpoint_l ) {
				padding: 20px;
				width: calc(50% - 41px);
			}

			@include breakpoint( $breakpoint_m ) {
				padding: 15px 10px;
				width: calc(100% - 20px);
				font-size: 18px;
				
				border-left: none;
				border-right: none;
			}

			strong{
				font-weight: 700;
			}
		}
	}
	

}