.page,
.menu{

	.tooltip{
		z-index: 1000000;
		min-width: 200px;
		opacity: 1 !important;
		margin: 0;
		padding: 0;
		border: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		&.place-left{
			&:after {
				background: darken($color_background, 3%);
				border-left-color: darken($color_background, 3%) !important;
			}
		}
		&.place-right{
			&:after{
				background: darken($color_background, 3%);
				border-right-color: darken($color_background, 3%) !important;
			}
		}
		&.place-top{
			&:after{
				background: darken($color_background, 3%);
				border-top-color: darken($color_background, 3%) !important;
			}
		}
		&.place-bottom{
			&:after{
				background: darken($color_background, 3%);
				border-bottom-color: darken($color_background, 3%) !important;
			}
		}

		color: $color_text;
		background: $color_background;
    	background: $color_item_gradient;

		&__media{
			flex: 1;
			margin: 0px;
			width: 200px;
			height: 200px;
			background-size: cover;
			background-position: center;
		}
		&__content{
			flex: 1;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			min-width: 210px;

			p{
				margin: 0;
				padding: 10px;
				text-transform: uppercase;
				

				&:empty{
					display: none;
				}

				// season
				&:nth-of-type(1){
					margin: 10px 0 30px 0px;
					font-weight: 400;
					font-size: 40px;
					line-height: 50px;
					font-family: "Poiret One", serif;
				}

				// season
				&:nth-of-type(2){
					margin: 0;
					font-weight: 700;
					font-size: 14px;
					line-height: 21px;
					text-transform: uppercase;
				}
			}
		}
	}
}