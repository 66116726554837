.page{

	/*** homepages ***/
	&--hometripple{
		
		.hometripple {
			margin: 0vh auto 0vh auto;
			width: 100vw;
			min-height: 100vh;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;

			@include breakpoint( $breakpoint_m ) {
				width: calc( 100vw - 80px);
				padding: 0 40px;
				flex-direction: column;
				justify-content: center;
			}
		

			&__links{
				
				width: 12vw;
				margin: 0 5vw 0 10vw;
				// max-width: 600px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: flex-start;

				@include breakpoint( $breakpoint_m ) {
					width: 100%;
					margin: 0;
				}

				&-head{
					position: relative;
					display: inline;
					width: 180px;
					// height: 180px;
					margin: 0 0 50px 10px;
					padding: 10px 10px 10px 0px;
					background: $color_text;
					background-size: 150%;
					background-position: center;
					background-repeat: repeat-x;
					background-clip: text;
					// -webkit-text-fill-color: transparent;
					transition: background-image .5s linear;

					/*
					animation: bg-slide 10s infinite linear;
					-moz-animation: bg-slide 10s infinite linear;
					-ms-animation: bg-slide 10s infinite linear;
					-webkit-animation: bg-slide 10s infinite linear;
					*/

					@include breakpoint( $breakpoint_l ) {
						// width: 150px;
						margin: 30px 0 10px 0;
					}
					@include breakpoint( $breakpoint_s ) {
						// width: 150px;
						margin: 30px 0 10px 0;
					}

					&-canvas{
						width: 180px;
						height: 180px;
					}

					h1{
						font-size: 40px;
						line-height: 50px;
						color: $color_text;
						font-weight: 700 !important;
						text-shadow: 0 0 0 rgba(0,0,0,.1);

						@include breakpoint( $breakpoint_l ) {
							max-width: 150px;
							font-size: 32px !important;
							line-height: 42px !important;
						}
					}
					
				}
				&-menu{
					display: flex;
					flex-direction: row;

					@include breakpoint( $breakpoint_l ) {
						margin: 50px 0 90px 0;
					}
					@include breakpoint( $breakpoint_m ) {
						display: none;
					}
				}
				&-pages{
					// width: 200px;
					margin: 0 40px 0 0;

					display: flex;
    				flex-direction: column;
					align-items: flex-start;

					@include breakpoint( $breakpoint_l ) {
						// width: 160px;
						margin: 0;
					}

					.hometripple__links-item{
						text-align: left;
						font-size: 18px;
						font-weight: 400;
						line-height: 17.7px;
						color: $color_text;
						word-break: break-all;
    					white-space: nowrap;

						@include breakpoint( $breakpoint_l ) {
							margin-left: -5px;
							font-size: 16px;
							font-weight: 400;
							line-height: 17px;
						}
					}
				}
				&-seasons{
					width: 200px;

					@include breakpoint( $breakpoint_l ) {

						width: 120px;

						.hometripple__links-item{
							font-size: 12px;
							line-height: 10px;
						}
					}

					@include breakpoint( $breakpoint_m ) {
						.hometripple__links-item{
							color: #bbb !important;
						}
					}
				}

				h1{
					
					margin: 10px 0 0 0;
					font-size: 50px;
					line-height: 55px;
					font-weight: 700;
					color: $color_text;
					font-family: $font_family_serif;
					// text-transform: uppercase;
				}
				p{
					max-width: 15vw;
					margin: 20px 0 0 0;
					font-size: 16px;
					line-height: 23px;
				}


				&-item{
					display: inline-block;
					//width: 50px;
					margin: 6px;
					padding: 2px;
					text-align: center;
					//transition: all .1s ease-in-out;
					border: none;
					background: none;

					font-size: 14px;
					line-height: 14px;
					border-bottom: 2px solid transparent;

					cursor: pointer;

					&.active,
					&:hover{
						color: $color_action;
						// border-bottom-color: $color_text;
					}

					@include breakpoint( $breakpoint_l ) {
						width: auto;
					}

					@include breakpoint( $breakpoint_m ) {
						margin: 3px 5px;
						padding: 5px;
					}

					a{
						text-decoration: none;
						color: $color_text;
					}
				}
			}

			&__content{

				&-items{
					position: relative;
					width: 68vw;
					margin: 0 0 0 5vw;
					display: flex;
					flex-wrap: nowrap;
					flex-direction: row;
					justify-content: space-between;
					
					overflow-x: scroll;
					// background: #f6f6f5;
					padding: 10px 0;

					&::-webkit-scrollbar { display: none; }
					-ms-overflow-style: none;
					scrollbar-width: none;

					color: $color_text;

					@include breakpoint( $breakpoint_m ) {
						width: 100vw;
						margin: 0;
					}

					& > div{
						position: relative;
						width: calc(100% - 84px);
						min-width: 370px;
						margin: 0 0 0 20px;
						padding: 40px 40px;
						
						background: $color_background;
    					background: $color_item_gradient;

						&:first-of-type{
							margin: 0;
						}

						&:last-of-type{
							margin-right: 34vw;

							@include breakpoint( $breakpoint_m ) {
								margin-right: 24vw;
							}
						}

						@include breakpoint( $breakpoint_m ) {
							width: calc( 90vw - 80px);
							min-width: auto;
							padding: 40px 40px 20px 40px;
						}
					}

					&-link{
						width: 100%;
						cursor: pointer;
						overflow: hidden;
					}

					&-media{
						width: 200px;
						height: 200px;
						background-size: cover;
						background-repeat: no-repeat;

						@include breakpoint( $breakpoint_m ) {
							width: 180px;
							height: 180px;
						}
					}
					h2{
						margin: 30px 0 60px 0px;
						font-weight: 400;
						font-size: 40px;
						line-height: 50px;
						font-family: 'Poiret One', serif;

						// color: $color_accent;

						@include breakpoint( $breakpoint_m ) {
							margin: 15px 0;
						}
					}
					h3{
						margin: 0;
						font-weight: 700;
						font-size: 14px;
						line-height: 21px;
						text-transform: uppercase;
					}
					p{
						display: none;
						margin: 0 0 0px 0;
						font-weight: 400;
						font-size: 14px;
						line-height: 21px;
						text-transform: uppercase;
					}

					.animate{
						min-height: 40px;
						width: 500px;
						
						@include breakpoint( $breakpoint_xl ) {
							width: 100%;
						}
					}

					&-term{
						// width: 400px;
						margin: 30px 0 0 0;
						font-size: 14px;
						line-height: 21px;
						font-weight: 400;
						text-transform: uppercase;

						@include breakpoint( $breakpoint_m ) {
							margin: 20px 0 0 0;
						}
					}

					button{
						width: 32px;
						border: none;
						background: none;
						cursor: pointer;
					}
				}

				&-navigation{
					width: 60vw;
					margin: 0 0 0 5vw;

					@include breakpoint( $breakpoint_m ) {
						width: 90vw;
					}

					display: flex;
					justify-content: space-around;

					span{
						position: relative;
						display: inline-block;
						width: 100%;
						height: 15px;
						margin: 0;
						padding: 0;
						border: none;
						background: none;

						transition: all .2s ease-in-out;

						cursor: pointer;

						&:before{
							content: '';
							position: absolute;
							top: 50%;
							display: block;
							width: 100%;
							height: 2px;
							background: darken( $color_background, 5% );
						}

						&:hover,
						&.active{
							&:before{
								background: $color_navigation;
							}
						}
						
					}
				}

				&-prevnext{
					margin: 10px 0 0 5vw;
					width: 100px;

					display: flex;
					flex-direction: row;
					justify-content: space-between;

					@include breakpoint( $breakpoint_m ) {
						margin: 10px 0 20px 0;
						width: 100%;
						justify-content: center;
					}

					&-prev{
						margin-left: 0 !important; 
					}
					&-next{
						margin-right: 0 !important; 
					}

					&-prev,
					&-next{
						display: flex;
						justify-content: center;
						align-items: center;

						margin: 0 10px;
						padding: 10px;
						background: none;
						border: none;
						cursor: pointer;

						border: 2px solid $color_navigation;
						// border-radius: 50%;

						svg{
							width: 20px;
							height: 20px;
							color: $color_navigation;

							transition: all .1s ease-in-out;
						}

						&:hover{
							svg{
								// color: $color_action;
							}	
						}

						&.disabled{
							pointer-events: none;
							border-color: rgba( $color_navigation, .25 );

							svg{
								color: rgba( $color_navigation, .25 );
							}
						}
					}
				}
			}
		}
	}
	&--hometv{

		min-height: 100vh;

		.hometv{

			height: 100vh;

			&__content{

				display: flex;
				flex-direction: column-reverse;
				justify-content: space-between;
				flex-wrap: nowrap;
				height: 100%;

				&-head{
					width: 94vw;
					height: 15.8vh;
					margin: 0;
					padding: 3vw 3vw;
					display: flex;
					justify-content: space-between;
					align-items: center;

					transition: all .6s ease-in-out;

					& > div{
						&:nth-of-type(1){
							// background: red;
						}
						&:nth-of-type(2){
							// background-color: red;
							display: flex;
							align-items: flex-end;
						}

						& > p{
							margin: 0;
						}
					}

					h1{
						margin: 0 0 30px 0;
						text-align: left;
						font-size: 55px;
						line-height: 55px;
						font-weight: 700;
						// color: $color_text;
						font-family: $font_family_serif;
					}
					p{
						margin: 0 auto 20px 0;
						font-weight: 400;
						font-size: 23px;
						line-height: 37px;
						text-align: left;
						// text-transform: uppercase;

						p{
							margin: 0;
						}

						small{
							display: block;
							margin: 10px 0 0 0;
							font-size: 16px;
							line-height: 24px;
							opacity: .8;
						}
					}

					.tv-info{
						// color: darken($color_background_accent, 5%);
					}

					img{
						margin: 0 0 0 3vw;
						width: 160px;
					}
					span{
						display: block;
						font-weight: 400;
						font-size: 23px;
					}
				}

				&-middle{
					height: 75vh;
				}

				&-items{
					position: relative;
					width: 100vw;
					height: 100%;
					margin: 0;
					display: flex;
					flex-wrap: nowrap;
					flex-direction: row;
					justify-content: space-between;
					
					overflow-x: scroll;
					// background: #f6f6f5;
					padding: 0;

					&::-webkit-scrollbar { display: none; }
					-ms-overflow-style: none;
					scrollbar-width: none;

					color: $color_text;

					& > div{
						position: relative;
						min-width: 100%;
						margin: 0;
						padding: 3vw 3vw;
						background: $color_item_gradient;

						transition: all .6s ease-in-out;
					}

					&-link{
						width: 100%;
						cursor: pointer;
						overflow: hidden;
						display: flex;
						justify-content: flex-start;
					}

					&-media{
						width: 700px;
						height: 700px;
						background-size: cover;
						background-repeat: no-repeat;
					}
					&-info{
						min-width: 40vw;
						margin: 0 0 0 10vw;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
					}

					h2{
						margin: -20px 0 0 0;
						font-weight: 700;
						font-size: 300px;
						line-height: 300px;
						font-family: 'Poiret One', serif;
					}
					h3{
						margin: 40px 0 0 0px;
						font-weight: 700;
						font-size: 38px;
						line-height: 38px;
						text-transform: uppercase;
					}
					p{
						display: none;
						margin: 0 0 0px 0;
						font-weight: 400;
						font-size: 40px;
						line-height: 50px;
						text-transform: uppercase;
					}

					.animate{
						min-height: 50px;
						width: calc( 100% - 0px);
					}

					&-term{
						// width: 400px;
						margin: 0;
						font-size: 38px;
						line-height: 38px;
						font-weight: 400;
						text-transform: uppercase;
					}

					button{
						width: 32px;
						border: none;
						background: none;
						cursor: pointer;
					}
				}

				&-navigation{
					position: fixed;
					bottom: 25.8vh;
					width: 100vw;
					margin: 0;
					padding: 5px 0;
					display: flex;
					justify-content: space-around;
					// background: darken( $color_background, 5% );

					span{
						position: relative;
						display: inline-block;
						width: 100%;
						height: 5px;
						margin: 0;
						padding: 0;
						background: none;
						transition: all .2s ease-in-out;
						cursor: pointer;

						border: none;
						border-right: 0px solid darken( $color_background, 0% );

						&:last-of-type{
							border: none;
						}

						&:before{
							content: '';
							position: absolute;
							top: 0;
							display: block;
							width: 100%;
							height: 5px;
							background: darken( $color_background, 8% );
							opacity: .25;
						}

						&.active{
							background: darken( $color_background, 0% );

							&:before{
								background: $color_text;
							}

							&.fx{
								&:before{
									animation-name: scaling;
									animation-iteration-count: 1;
									transform-origin: top left;
									animation-duration: 15s;
									animation-timing-function: linear;
								}
							}
						}
						
					}
				}

				&-arrows{

					display: none;
					overflow: hidden;

					&-item{
						position: fixed;
						top: calc(50% - 25px);
						margin: 0;
						padding: 0;
						border: none;
						color: $color_navigation;

						&--left{
							left: 0;
							&.fx{
								transform: rotate(-360deg);
							}
						}	
						&--right{
							right: 0;
							&.fx{
								transform: rotate(360deg);
							}
						}

						&.fx{
							transition: all .45s ease-in-out;
						}

						svg{
							width: 50px;
							height: 50px;
						}
					}
				}

			}
		}

		&[data-theme="white"]{
			.hometv__content{
				&-head{
					background: $color_background_accent;
					color: #fff;

					img{
						mix-blend-mode: screen;
					}
				}
				&-items > div{
					// background: $color_action;
					// color: $color_text_light;
				}
			}
		}
		&[data-theme="red"]{
			.hometv__content{
				&-head{
					background: $color_background_dark;
					color: $color_text_light;

					img{
						mix-blend-mode: screen;
					}
				}
				&-items > div{
					background: $color_action;
					color: $color_text_light;
				}
			}
		}
		&[data-theme="black"]{
			.hometv__content{
				&-head{
					background: red;
					color: $color_text_light;
					img{
						mix-blend-mode: screen;
					}
				}
				&-items > div{
					background: $color_background_dark;
					color: $color_text_light;
				}
			}
		}
		&[data-theme="blue"]{
			.hometv__content{
				&-head{

					img{
						mix-blend-mode: multiply;
					}
				}
				&-items > div{
					background: $color_background_accent;
					color: $color_text_light;
				}
			}
		}
	}


	/*** content pages ***/
	&--season{
		padding: 0px;

		.section{

			h2{
				&:not(.slider__item > h2){
					strong{
						display: block;
						text-transform: uppercase;
						font-family: $font_family_serif;
						font-weight: 700;
						// color: $color_action;
						&:before{
							content: '~ ';
						}
						&:after{
							content: ' ~';
						}
					}
				}
			}

			h3{
				&:not(.slider__item > h3){
					strong{
						display: block;
						text-transform: uppercase;
						font-family: $font_family_serif;
						font-weight: 700;
						// color: $color_action;
						&:before{
							content: '~ ';
						}
						&:after{
							content: ' ~';
						}
					}
				}
			}

			&:first-of-type{
				margin: 10vh 0 25vh 0;

				@include breakpoint( $breakpoint_l ) {
					margin: 10vh 0 20vh 0;
				}
				@include breakpoint( $breakpoint_m ) {
					margin: 12vh 0 15vh 0;
				}

				.btn{
					background: $color_action;
					border: none;
					color: #fff;
					margin: 0;
					padding: 10px 25px;
					font-size: 16px;
					outline: none;
					transition: all .1s ease-in-out;
					cursor: pointer;
		
					&:hover{
						background: darken( $color_action, 15% );
					}
				}
			}

			&:first-of-type:not(.section--expanded){
				.article{
	
					display: none;
	
					&--first{
	
						display: block;
	
						p > p{
							
							display: none;
	
							&:first-of-type{
								display: block;
	
								
							}
						}
					}
				}
			}

			&--dts_fullwidth{

				.article:not(.article--article_videofull){

					h2, h3{
						max-width: none;
						margin: 10px 0;
						font-size: 50px;

						@include breakpoint( $breakpoint_l ) {
							font-size: 40px;
						}
						@include breakpoint( $breakpoint_m ) {
							font-size: 30px;
						}
					}

					ul,ol{
						h3{
							em{
								font-size: 22px;
								line-height: 30px;
								font-style: normal;
								// text-transform: capitalize;
								font-family: $font_family_text;
							}
						}
					}

					&:nth-child(odd){
						ul,ol{
							background: darken( $color_background_accent, 0);
							// border-color: darken( $color_background_accent, 3% );
							color: $color_text_light;
							li{
								border-right: 1px solid darken( $color_background_accent, 3% );
								border-bottom: 1px solid darken( $color_background_accent, 3% );
							}
						}
						.article__content{
							background: $color_background_accent;
							border-color: darken( $color_background_accent, 0 );
							color: $color_text_light;
						}
					}
					&:nth-child(even){
						ul,ol{
							background: darken( $color_background_accent, 3% );
							// border-color: darken( $color_background_accent, 6% );
							color: $color_text_light;
							li{
								border-right: 1px solid darken( $color_background_accent, 6% );
								border-bottom: 1px solid darken( $color_background_accent, 6% );
							}
						}
						.article__content{
							background: darken( $color_background_accent, 5%);
							border-color: darken( $color_background_accent, 5% );
							color: $color_text_light;
						}
					}
				}
			}
		}
	}
	&--terms{
		padding: 50px 0px;
	}
	&--generic{
		padding: 50px;

		.page__links{
			left: 0;
		}
	}
	&--not-found{
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h1{
			margin: 0;
		}
		p{
			margin: 0;
		}
		a{
			margin: 10px 0 0 0;
			text-decoration: none;
			color: $color_action;
		}
	}

	/*** specific pages ***/
	&--galerija,
	&--gallery{
		padding: 50px;

		@include breakpoint( $breakpoint_m ) {
			padding: 10px;
		}

		.modal{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			display: flex;
			flex-direction: column;

			&__wrapper{
				position: relative;
				margin: 0px;
				padding: 20px 40px;
				height: calc(100vh - 40px);
				overflow-y: auto;
				background: #eee;
			}
		}
	}

	&--medijske-objave{
		padding: 50px;
		min-height: 50vh;

		@include breakpoint( $breakpoint_m ) {
			padding: 10px;
		}

		.section{
			&.fixed{
				&:first-of-type{
					margin-top: 175px;
				}
			}
		}

		.article{
			min-width: 50vw;
		}

		.articles-menu{
			flex-direction: column;

			&__list{

				display: flex;
				flex-wrap: wrap;

				button{
					cursor: pointer;
					transition: all .1s ease-in-out;

					margin: 20px;
					padding: 20px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					border: none;
					background: darken( $color_background, 3% );
					font-size: 20px;
					text-align: left;
					
					width: 8vw;
					@include breakpoint( $breakpoint_xl ) {
						width: calc( 25vw - 66px);
					}
					@include breakpoint( $breakpoint_l ) {
						width: calc( 33vw - 71px);
					}
					@include breakpoint( $breakpoint_m ) {
						width: calc( 50vw - 50px);
					}
					@include breakpoint( $breakpoint_s ) {
						margin: 10px 20px;
						width: calc( 1000% - 0px);
					}

					&:hover{
						background: darken( $color_background, 6% );
					}

					small{
						margin: 5px 0 0 0;
						font-size: 13px;
						opacity: .35;
					}
				}
			}

			&__detail{
				min-width: 280px;
				margin: 0px 0;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: stretch;
				justify-content: space-between;
				transition: all .2s ease-in-out;

				@include breakpoint( $breakpoint_xl ) {
					justify-content: center;
				}

				span{
					// display: none;
					pointer-events: none;
					padding: 15px 10px 15px 10px;
					// color: $color_text;
					// background: $color_background;
					font-size: 25px;
					line-height: 23px;
					font-weight: 400;
					font-family: $font_family_text;
					text-transform: uppercase;
				}
				label{
					padding: 15px 20px;
					color: $color_text;
					font-size: 45px;
					line-height: 43px;
					font-weight: 300;
					font-family: $font_family_numbers;
					text-align: center;
					cursor: pointer;
				}

				&-item,
				button{
					cursor: pointer;
					transition: all 0.1s ease-in-out;
					margin: 20px;
					padding: 20px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					border: none;
					background: #e9e9e0;
					font-size: 20px;
					text-align: left;
					width: 8vw;

					// background: darken( $color_background, 5% );
					cursor: pointer;
					transition: all .2s ease-in-out;

					@include breakpoint( $breakpoint_xl ) {
						min-width: 33.3%;
						margin: 0;
						align-items: center;
					}

					&.disabled{
						pointer-events: none;
						// background: #e7e7e7;
						color: #ccc;
					}

					&--accent{
						background: $color_background_accent;
						color: #fff;

						@include breakpoint( $breakpoint_xl ) {
							width: 100%;
							align-items: flex-start !important;
						}
					}

					&:hover{
						// background: $color_background_dark;
						// color: $color_text_light;
					}

					small{
						margin: 20px 0 0 0;
						font-size: 13px;
						line-height: 16px;
						text-transform: none;
					}
				}

				@include breakpoint( $breakpoint_m ) {
					font-size: 20px;
				}
			}

			&-modal{
				position: fixed;
				z-index: 200009;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				display: flex;
				align-items: center;
				justify-content: center;

				// background: rgba($color_background, .95);

				&__wrapper{
					margin: 0;
					padding: 30px 10px;
					// max-width: 50vw;
					// height: 50vh;
					color: $color_text_light;
					background: $color_background_dark;

					display: flex;
					flex-direction: row;
					flex-flow: wrap;
					justify-content: center;
					align-items: center;
					
					width: 25vw;
					@include breakpoint( $breakpoint_xl ) {
						width: 33vw;
					}
					@include breakpoint( $breakpoint_l ) {
						width: 50vw;
					}
					@include breakpoint( $breakpoint_m ) {
						width: calc( 90vw - 40px);
					}
				}

				&__item{
					// min-width: 100px;
					margin: 10px 15px;
					font-size: 20px;
					line-height: 28px;
					font-weight: 400;
					font-family: $font_family_text;
					color: $color_text;
					cursor: pointer;
					transition: all .2s ease-in-out;
					text-align: center;

					&.active,
					&:hover{
						color: $color_accent;
					}

					@include breakpoint( $breakpoint_m ) {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}

		.media--download{
			margin: 10px 0;
		}

		.modal{
			/*
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			*/

			display: flex;
			flex-direction: column;

			&__wrapper{
				position: relative;
				margin: 20px 0 0 0;
				padding: 60px 40px;
				// height: calc(100vh - 40px);
				// overflow-y: auto;
				background: $color_background;

				@include breakpoint( $breakpoint_m ) {
					padding-top: 0;
				}

				&-content{
					
					display: flex;
					flex-direction: row;
					align-items: flex-start;

					@include breakpoint( $breakpoint_l ) {
						flex-direction: column;
					}

					& > div{
						margin: 0 50px;

						@include breakpoint( $breakpoint_xl ) {
							margin: 0 10px;
						}
						@include breakpoint( $breakpoint_l ) {
							margin: 0 10px;
						}


						&:first-of-type{
							margin-left: 0;
						}
						&:last-of-type{
							margin-right: 0;
						}

						h3{
							margin: 0 0 50px 0;
							font-size: 40px;
							line-height: 45px;
							font-weight: 700;
							word-break: normal;
							white-space: nowrap;
							text-transform: uppercase;

							@include breakpoint( $breakpoint_l ) {
								font-size: 30px;
								margin: 100px 0 50px 0;

								&:first-of-type{
									margin-top: 0;
								}
							}
						}
					}

					&--text{
						margin-left: 0;
					}
					&--downloads{
						
					}
					&--videos{

					}
					&--audios{
						
					}
					
				}

				&-media{
					margin: 20px 0;
				}
			}

			h3{
				margin: 20px 0 0 0;
			}
		}

		

		.block-napomena{
			position: relative;
			font-size: 21px;
			line-height: 29px;
			padding: 40px;
			margin: 20px 0;
			background: darken( $color_background, 3% );
			border: none;
			// border: 2px dashed darken( $color_background, 15% );

			@include breakpoint( $breakpoint_m ) {
				font-size: 17px;
				line-height: 24px;
			}

			strong{
				font-size: 16px;
				line-height: 26px;
			}

			&::before{
				position: absolute;
				top: 5px;
				left: 5px;
				content: '"';
				font-size: 40px;
				line-height: 40px;
				color: darken( $color_background, 9%);
				font-family: $font_family_text;
			}
			&::after{
				position: absolute;
				bottom: 5px;
				right: 5px;
				content: '"';
				font-size: 40px;
				line-height: 0px;
				color: darken( $color_background, 9%);
				font-family: $font_family_text;
			}
		}
	}

	&--razno{
		padding: 50px;
		min-height: 50vh;

		@include breakpoint( $breakpoint_m ) {
			padding: 10px;
		}

		.section{
			&.fixed{
				&:first-of-type{
					margin-top: 175px;
				}
			}
		}

		

		.gallery{
			flex-direction: column;
			width: 100%;

			&__item{
				width: calc( 100% - 80px );
				flex-direction: row;
				cursor: default;

				@include breakpoint( $breakpoint_m ) {
					flex-direction: column;
				}

				&:hover{
					transform: none;
				}

				h2{
					margin: 0;

					@include breakpoint( $breakpoint_m ) {
						margin: 0 0 20px 0;
					}
				}

				&-links{
					width: 75%;

					@include breakpoint( $breakpoint_m ) {
						width: 100%;
					}

					button{
						margin: 20px 10px;
						display: block;
						background: none;
						border: none;
						font-size: 16px;
						// font-weight: 700;
						text-align: left;

						display: flex;
						align-items: center;

						transition: all .15s ease-in-out;

						cursor: pointer;

						& > *{
							pointer-events: none;
						}

						&:first-of-type{
							margin-top: 0;
						}
						&:last-of-type{
							margin-bottom: 0;
						}

						&:hover{
							color: $color_accent;

							svg{
								color: $color_accent;
								border-color: $color_accent;
							}
						}

						svg{
							margin: 0 20px 0 0;
							padding: 5px 10px;
							min-width: 24px;
							min-height: 24px;
							border: 2px solid #222;
							transition: all .15s ease-in-out;
						}
					}
				}
			}

			/*
			&.fixed{
				position: fixed;
				z-index: 1000;
				top: -50px;

				.gallery-selected{
					background: $color_background_dark;

					@include breakpoint( $breakpoint_m ) {
						min-width: auto;
					}

					button{
						padding: 5px 10px;
					}

					label{
						padding: 13px 20px;
						font-size: 25px;
						line-height: 23px;
						color: $color_text_light;
					}
				}
			}

			&-selected{
				min-width: 280px;
				margin: 50px 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				// background: $color_background_dark;
				transition: all .2s ease-in-out;

				span{
					display: none;
					pointer-events: none;
					padding: 15px 10px 15px 10px;
					// color: $color_text;
					// background: $color_background;
					font-size: 25px;
					line-height: 23px;
					font-weight: 400;
					font-family: $font_family_text;
					text-transform: uppercase;
				}
				label{
					padding: 15px 20px;
					color: $color_text;
					font-size: 45px;
					line-height: 43px;
					font-weight: 300;
					font-family: $font_family_numbers;
					text-align: center;
					cursor: pointer;
				}
				button{
					border: none;
					margin: 0;
					padding: 15px 14px;
					font-size: 25px;
					line-height: 2px;
					color: $color_accent;
					background: none;
					// background: darken( $color_background, 5% );
					cursor: pointer;
					transition: all .2s ease-in-out;

					&.disabled{
						pointer-events: none;
						// background: #e7e7e7;
						color: #ccc;
					}

					&:hover{
						// background: $color_background_dark;
						// color: $color_text_light;
					}
				}

				@include breakpoint( $breakpoint_m ) {
					font-size: 20px;
				}
			}
			*/
			&-modal{
				/*
				position: fixed;
				z-index: 200009;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				
				display: flex;
				align-items: center;
				justify-content: center;
				*/

				// background: rgba($color_background, .95);

				&__wrapper{
					margin: 0;
					padding: 30px 10px;
					// max-width: 50vw;
					// height: 50vh;
					color: $color_text;
					background: $color_background;

					/*
					display: flex;
					flex-direction: column;
					flex-flow: wrap;
					justify-content: center;
					align-items: center;
					*/
					
					width: calc( 100vw - 20px );
					// height: calc( 100vh - 60px );
				}

				&__content{
					h3{
						display: none;
					}
				}

				&__item{
					min-width: 100px;
					margin: 10px;
					font-size: 20px;
					line-height: 28px;
					font-weight: 400;
					font-family: $font_family_text;
					color: $color_text_light;
					cursor: pointer;
					transition: all .2s ease-in-out;
					text-align: center;

					&.active,
					&:hover{
						color: $color_accent;
					}

					@include breakpoint( $breakpoint_m ) {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}

		.media--lightbox{
			margin: 10px 0;
			background: $color_background;
		}

		.modal{
			/*
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			*/

			display: flex;
			flex-direction: column;

			&__wrapper{
				position: relative;
				margin: 20px 0 0 0;
				padding: 60px 40px;
				// height: calc(100vh - 40px);
				// overflow-y: auto;
				background: $color_background;

				@include breakpoint( $breakpoint_m ) {
					padding-top: 0;
				}

				&-content{
					
					display: flex;
					flex-direction: row;
					align-items: flex-start;

					@include breakpoint( $breakpoint_l ) {
						flex-direction: column;
					}

					& > div{
						margin: 0 50px;

						@include breakpoint( $breakpoint_xl ) {
							margin: 0 10px;
						}
						@include breakpoint( $breakpoint_l ) {
							margin: 0 10px;
						}


						&:first-of-type{
							margin-left: 0;
						}
						&:last-of-type{
							margin-right: 0;
						}

						h3{
							margin: 0 0 50px 0;
							font-size: 40px;
							line-height: 45px;
							font-weight: 700;
							word-break: normal;
							white-space: nowrap;
							text-transform: uppercase;

							@include breakpoint( $breakpoint_l ) {
								font-size: 30px;
								margin: 100px 0 50px 0;

								&:first-of-type{
									margin-top: 0;
								}
							}
						}
					}

					&--text{
						margin-left: 0;
					}
					&--downloads{
						
					}
					&--videos{

					}
					&--audios{
						
					}
					
				}

				&-media{
					margin: 20px 0;
				}
			}

			h3{
				margin: 20px 0 0 0;
			}
		}
	}

	&--search{
		min-height: 50vh;
		padding: 50px 0;
	}

	&--publikacije,
	&--publications{

		.section{
			margin: 0;
		}

		.article{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 50px 0 0 0;
			padding: 50px;
			
			border-bottom: 1px solid #eee;

			&:first-of-type{
				border: none;	
				h2{
					font-size: 50px;
					text-transform: none;

					@include breakpoint( $breakpoint_l ) {
						font-size: 35px;
					}
				}
			}
			&:last-of-type{
				border: none;	
			}

			@include breakpoint( $breakpoint_m ) {
				// width: 100%;
				flex-direction: column;
				margin: 50px 0 0 0;
			}


			h2{
				// width: 200px;
				order: 1;
				// margin: 0 50px 0 0;

				// font-size: 24px;
				// line-height: 32px;
				// font-weight: 700;
				// padding: 0 20px;
				text-transform: uppercase;

				@include breakpoint( $breakpoint_l ) {
					margin: 0 50px 50px 0;
				}

				@include breakpoint( $breakpoint_m ) {
					margin: 0;
					text-align: center;
    				width: auto;
				}
			}

			&__media{
				width: 250px;
				margin: 0;
				order: 3;

				@include breakpoint( $breakpoint_m ) {
					width: auto;
				}

				.media{

					&--download{
						margin: 50px 0 0 0;
						// width: 100%;

						@include breakpoint( $breakpoint_m ) {
							// width: auto;
							margin: 0 auto 20px auto;
						}
					}
					
				}
			}

			p{
				max-width: none;
				width: 250px;
				font-size: 16px;
				line-height: 25px;
				order: 2;

				@include breakpoint( $breakpoint_l ) {
					width: auto;
				}

				@include breakpoint( $breakpoint_m ) {
					width: 100%;
					margin: 10px 0;
					text-align: center;
				}
			}

			&:first-of-type{
				h2{
					width: auto;
				}
			}
		}
	}

	&--eurokaz-produkcija,
	&--eurokaz--production{
		a{
			color: $color_text;
			text-decoration: underline;
			transition: color .1s ease-in-out;
	
			&:hover{
				color: $color_accent;
			}
		}
	}




	// elements
	h1{
		font-size: 40px;
	}
	p{
		max-width: 50vw;
		font-size: 22px;
		line-height: 30px;

		@include breakpoint( $breakpoint_m ) {
			max-width: none;
		}
	}


	// page season
	

	&__head{
		// background: #fff;
		// color: #fff;
		padding: 0px 0px 0px 0px;

		&:empty{
			height: 100vh;
		}

		@include breakpoint( $breakpoint_m ) {
			padding: 0px;
		}

		&-wrapper{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100vh;

			@include breakpoint( $breakpoint_m ) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-top: 48px;
				height: 85vh;
			}

			&.fixed{
				h2{
					position: fixed;
					z-index: 50;
					left: 0;
					top: 0;
					padding: 9px 20px;
					font-size: 25px;
					line-height: 30px;
					font-weight: 900;
					background: $color_background_dark;
					color: #fff;
					cursor: pointer;
					transition: all .2s ease-in;

					&:hover{
						background: $color_action;
					}
				}
			}

			& > div:first-of-type{
				// width: calc( 50vw - 100px );
				// min-height: calc( 75vh - 100px );
				padding: 50px;
				
				background: $color_background;
    			background: $color_item_gradient;

				@include breakpoint( $breakpoint_m ) {
					width: calc(100vw - 60px);
					padding: 30px;
					min-height: auto;
				}
			}

			.animate{
				text-transform: uppercase;
			}
		}

		&-content{
			// position: absolute;
			z-index: 3; 
			// cursor: url(#{$path_assets}img/arrow_down.png), s-resize;

			font-family: $font_family_text;
			align-self: center;
			margin: 20px 0 0 0;
			width: 25vw;

			@include breakpoint( $breakpoint_m ) {
				position: relative;
				order: 2;
				flex: auto;
				align-self: flex-start;
				margin: 20px 0 0 0;
				width: calc( 100vw - 40px );
			}
		}

		.hometripple__content-items-media{
			width: 50vw;
			height: 50vh;
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;

			@include breakpoint( $breakpoint_m ) {
				width: 100%;
			}

			&--cursor{
				// cursor: url(#{$path_assets}img/arrow_down.png), s-resize;
			}
		}

		&-scroll-icon{
			display: inline-block;
			position: fixed;
			z-index: 50;
			bottom: 10px;
			left: calc( 50% - 10px);
			width: 20px;
			height: 20px;
			overflow: hidden;
			text-align: center;
			font-size: 20px;
			line-height: 20px;
			font-weight: 700;
			transition: all .2s ease-in-out;
			cursor: pointer;

			animation-name: bounce;
			animation-duration: 1s;
			animation-play-state: running;
			animation-iteration-count: infinite;
			transform-origin: center center;

			&.hidden{
				bottom: -100px;
			}

			svg{
				color: $color_text;
			}

			@include breakpoint( $breakpoint_m ) {
				// bottom: 80px;
			}
		}

		h2{
			margin: 0 0 0 0;
			padding: 10px 0;
			font-size: 50px;
			font-weight: 400;
			font-family: 'Poiret One', serif;
			color: $color_text;

			@include breakpoint( $breakpoint_s ) {
				font-size: 45px;
				margin: 0px 0 0 0;
			}
		}
		a{
			color: $color_action;
			text-decoration: none;
			//font-size: 100px;

			@include breakpoint( $breakpoint_s ) {
				//font-size: 75px;
			}
		}
		h3{
			margin: 30px 0 0 0px;
			text-transform: uppercase;
			font-family: $font_family_text;
			color: $color_text;

			@include breakpoint( $breakpoint_s ) {
				margin: 20px 0 0 0px;
			}
		}
		p{
			margin: 0 0 40px 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			text-transform: uppercase;
			font-family: $font_family_text;
			color: $color_text;

			@include breakpoint( $breakpoint_m ) {
				margin: 0 0 20px 0;
			}
		}

		
		.media{
			width: calc( 100% - 60px);
			height: calc( 100vh - 60px );
			overflow: hidden;
			margin: 0px 60px 0 0;

			@include breakpoint( $breakpoint_m ) {
				order: 1;
				flex: auto;
				width: calc( 100vw + 0px);
				height: auto;
				margin: 0px -20px;
			}

			.media-item{
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&__content{
		margin: 0px 0 0 0;
		width: 100%;
    	word-break: break-word;

		color: $color_text;
	}
}