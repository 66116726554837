.terms-head{
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	margin: 50px;

	@include breakpoint( $breakpoint_m ) {
		flex-direction: column;
	}

	h1{
		margin: 0 0 0 20px;
		color: $color_text;

		@include breakpoint( $breakpoint_m ) {
			margin: 0;
		}
	}

	input{
		margin: 0 0 0 100px;
		min-width: 300px;
		padding: 10px 15px;
		border: none;
		background: #fff;
		color: $color_text;
		font-size: 16px;
		outline: none;

		&::placeholder{
			font-size: 13px;
			font-style: italic;
			color: rgba( $color_text, 20% );
		}

		@include breakpoint( $breakpoint_m ) {
			min-width: auto;
			width: 100%;
			margin: 20px 0 0 0;
		}
	}
}

.terms{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 50vw;
	padding: 0 25vw;

	@include breakpoint( $breakpoint_m ) {
		flex-direction: column;
		width: 70vw;
		padding: 0 15vw;
	}

	.term-category{
		margin: 80px 15px 20px 15px;
		font-size: 60px;
		line-height: 45px;
		font-family: $font_family_serif;
		font-weight: 700;
		color: $color_background_accent;
		display: block;
		width: 100%;

		@include breakpoint( $breakpoint_m ) {
			margin: 30px 0 10px 0;
			font-size: 50px;
			line-height: 45px;
		}
		@include breakpoint( $breakpoint_s ) {
			font-size: 40px;
			line-height: 30px;
		}
	}

	.term{
		position: relative;
		margin: 10px 0px;
		padding: 5px 15px;
		text-decoration: none;
		color: $color_text;

		display: inline-flex;
		flex-direction: row;
		align-items: flex-start;

		transition: all .2s ease-in-out;

		@include breakpoint( $breakpoint_m ) {
			margin: 3px;
			padding: 0;
			font-size: 22px;
		}
		@include breakpoint( $breakpoint_s ) {
			margin: 2px;
			font-size: 15px;
		}

		&.active,
		&:hover{

			.term__title{
				// font-style: italic;
				// color: $color_action;
			}
			.term__season{
				// font-style: italic;
				// color: $color_action;
			}
		}

		&__title{
			margin: 0 10px 0 0;
			font-size: 35px;
			line-height: 45px;
			// word-wrap: break-word;
			// word-break: break-all;
			//white-space: nowrap;
			transition: all .2s ease-in-out;
			text-decoration: none;
			color: $color_text;
			transition: all .2s ease-in-out;

			&:hover{
				// cursor: url(#{$path_assets}img/arrow_right.png), e-resize;
			}

			&--link{
				&:hover{
					cursor: pointer;
					text-decoration: underline;
				}
			}

			@include breakpoint( $breakpoint_m ) {
				font-size: 25px;
				line-height: 35px;
			}
			@include breakpoint( $breakpoint_s ) {
				font-size: 18px;
				line-height: 28px;
			}
		}

		&__seasons{
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			flex-wrap: wrap;
			// word-break: break-all;
			margin: 4px 0 0 0;

			@include breakpoint( $breakpoint_s ) {
				margin: 2px 0 0 0;
			}
		}
		&__season{
			flex: 1;
			margin: 0 0 0 10px;
			padding: 4px 6px; 
			font-size: 20px;
			// line-height: 45px;
			font-weight: 400;
			color: $color_text;
			text-decoration: none;
			transition: all .2s ease-in-out;

			&:hover{
				// text-decoration: underline;
				color: $color_action;
			}

			@include breakpoint( $breakpoint_m ) {
				margin: 0 0 0 5px;
				font-size: 15px;
				// line-height: 30px;
			}
			@include breakpoint( $breakpoint_s ) {
				margin: 0 0 0 3px;
				font-size: 12px;
				// line-height: 20px;
			}
		}
	}
}