.page__links{
	margin: 0px;
	padding: 18px 50px 18px 25px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-wrap: nowrap;
	// width: calc( 100% - 75px );
	// height: calc( 100vh - 36px);

	position: fixed;
	z-index: 70;
	top: 0;
	bottom: auto;

	color: $color_text;
	border-top: 1px solid transparent;

	overflow-x: auto;

	transition: all .3s ease-in-out;

	scroll-behavior: smooth;
	overscroll-behavior: contain;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}

	&:empty{
		display: none;
	}

	&.hidden{
		top: -100px;
		bottom: auto;

		@include breakpoint( $breakpoint_m ) {
			top: auto;
			bottom: -100px;
		}
	}
	&--border{
		border-top-color: #eee;
	}

	@include breakpoint( $breakpoint_l ) {
		display: none;
	}
	@include breakpoint( $breakpoint_m ) {
		bottom: 0px;
		top: auto;
	}

	.link{
		margin: 0 0 5px 0;
		padding: 2px 0;
		font-size: 12px;
		line-height: 14px;
		white-space: nowrap;
		cursor: pointer;
		opacity: 1;
		transition: all .2s ease-in-out;
		text-decoration: none;
		border-bottom: 2px solid transparent;

		color: $color_text;

		&--section{
			font-weight: 700;
		}
		&--season{
			font-weight: 400;
		}

		&--divider{
			opacity: 1;
			border-left: 2px solid $color_text;
			height: 12px;
			margin: 3px 30px 0 0px;
		}

		&:active,
		&:hover,
		&.is-active{
			opacity: 1;
			// color: $color_action;
			border-bottom-color: $color_text;
		}
	}
}