// page home
.poster{
	width: calc( 50vw - 60px );
	height: calc( 100vh - 0px );
	position: relative;
	display: flex;
	margin: 0px 60px 0 0 ;
	//overflow: hidden;		
	background-color: #fff;

	@include breakpoint( $breakpoint_m ) {
		width: 100vw;
		height: 60vh;
		flex-direction: column;
		margin: 0;
	}

	&__wrapper{
		position: relative;
		height: 100%;
	}

	&__navigation{
		position: fixed;
		z-index: 79;
		width: calc( 50vw - 60px );
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		@include breakpoint( $breakpoint_m ) {
			display: none;
		}

		&-minus{
			flex: 1;
			border: none;
			background: none;
			cursor: url(#{$path_assets}img/arrow_up.png), n-resize;
		}
		&-season{
			flex: 3;
			border: none;
			background: none;
			cursor: url(#{$path_assets}img/arrow_right.png), e-resize;
		}
		&-plus{
			flex: 1;
			border: none;
			background: none;
			cursor: url(#{$path_assets}img/arrow_down.png), s-resize;
		}
	}

	&__content{
		width: 100%;
		height: 100%;
		margin: 0 60px 0 0;

		@include breakpoint( $breakpoint_m ) {
			margin: 0;
			height: 60vh;
			overflow: hidden;
		}

		&-media{
			position: relative;

			&.active{
				.poster__content-media-overlay{
					background: none;
					//mix-blend-mode: normal;
				}
			}
			&.hover{
				.poster__content-media-overlay{
					background: $color_action;
					opacity: .85;
				}
			}
			&:not(.active){
				.poster__content-media-overlay{
					background: $color_background_dark;
				}
			}

			&-overlay{
				position: absolute;
				z-index: 9;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: all .25s ease-in-out;
				opacity: 1;
				mix-blend-mode: darken;
			}

			img{
				width: 100%;
				display: block;
				transform-origin: center center;
				transition: all .25s ease-in-out;

				//mix-blend-mode: multiply;
			}
		}
	}

	

	&__info{

		position: fixed;
		left: 100px;
		z-index: 60;
		flex: 1;
		width: 40vw;
		min-height: 70vh;
		background: rgba(255,255,255,1);
		transition: all .2s ease-in-out;

		@include breakpoint( $breakpoint_l ) {
			left: 20px;
			width: calc( 50vw - 20px );
		}

		@include breakpoint( $breakpoint_m ) {
			left: 0;
			flex: 1;
			width: calc( 100% - 0px );
			min-height: 40vh;
			bottom: 0;
		}

		&-wrapper{
			margin: 50px;
			padding: 20px 30px;
			height: calc( 100% - 100px);

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			color: $color_text;

			@include breakpoint( $breakpoint_l ) {
				height: calc( 100% - 30px);
				margin: 10px 30px;
				padding: 15px 20px;
			}
			@include breakpoint( $breakpoint_m ) {
				height: calc( 100% - 50px);
				margin: 0 25px;
				padding: 15px 20px;
			}
		}

		.animate-title{
			// display: none;
			// position: fixed;
			z-index: 65;
			left: 0;
			top: 0;
			padding: 10px;
			text-transform: uppercase;

			&__year{
				transition: all .2s ease-in-out;
				font-family: 'Poiret One', serif;
				font-size: 80px;
				font-weight: 300;
				color: $color_text;

				&:hover{
					transform: translateX(20px);
				}

				@include breakpoint( $breakpoint_m ) {
					font-size: 60px;
				}
			}
			&__text{
				// display: none !important;
				padding: 50px 10px 0 10px;
				font-size: 28px;
				font-weight: 400;
				color: $color_text;
				transition: all .4s ease-in-out;

				@include breakpoint( $breakpoint_m) {
					padding: 10px 10px 0 10px;
					font-size: 20px;
				}
			}
		}
		.animate-description{
			// display: none;
			// position: fixed;
			z-index: 65;
			font-size: 28px;
			font-weight: 400;
			padding: 0px 0 15px 15px;
			color: $color_text;
			text-transform: uppercase;

			@include breakpoint( $breakpoint_m ) {
				font-size: 20px;
			}

			@include breakpoint( $breakpoint_s ) {
				//display: none;
			}
		}
		.animate-terms{
			// display: block;
			// position: fixed;
			z-index: 65;
			font-size: 28px;
			font-weight: 400;
			// font-family: 'Poiret One', serif;

			padding: 60px 15px 0 15px;

			@include breakpoint( $breakpoint_m ) {
				padding: 10px 15px 0 15px;
				font-size: 20px;
			}
			
			& > div{
				// justify-content: center;
				display: block;

				& > div{
					// min-width: 400px;
					// text-align: center;
					// white-space: nowrap;
					// word-break: break-all;
					text-transform: uppercase;
				}
			}
			
		}
		.animate-progress{
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1px;
			margin: 3px 10px;
			width: calc( 100% - 20px );
			border-bottom: 1px solid rgba(0,0,0,.21);
			animation-name: scaling;
			animation-iteration-count: 1;
			transform-origin: top left;

			@include breakpoint( $breakpoint_s ) {
				bottom: 0;
				border-bottom: 1px solid rgba(0,0,0,.25);
			}
		}
		.animate-media-title{
			margin: 100px 0 0 0;
			width: calc( 100% - 30px);
			// position: fixed;
			// z-index: 90;
			// right: 60px;
			// bottom: 0px;
			// width: calc(50vw - 60px - 40px);
			padding: 15px 15px;
			font-size: 13px;
			font-weight: 400;
			line-height: 21px;
			text-transform: uppercase;
			// background: rgba(0,0,0,.9);
			color: $color_text;
			word-break: break-all;
			transition: all .2s ease-in-out;

			display: flex;
			justify-content: flex-start;

			&--empty{
				opacity: 0;
			}

			display: none;
			@include breakpoint( $breakpoint_l ) {
				display: none;
			}

			

			& > div{
				// height: auto !important;
			}
		}

		
		h1{
			margin: 0 0 0 0;
			font-size: 70px;
			font-weight: 300;

			@include breakpoint( $breakpoint_s ) {
				font-size: 45px;
				margin: 0 0 0 0;
			}
		}
		a{
			color: $color_text;
			text-decoration: none;
			//font-size: 100px;

			@include breakpoint( $breakpoint_s ) {
				//font-size: 75px;
			}
		}
		h3{
			margin: 40px 0 0 15px;
			font-size: 15px;
			line-height: 24px;
			font-weight: 400;

			@include breakpoint( $breakpoint_s ) {
				display: none;
			}
		}
		p{
			margin: 0;
			padding: 0;
		}
	}

	&__media{
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% - 50vw - 0px);
		height: calc( 100% - 0px );
		margin: 0px 0px 0px 50vw;
		background-size: contain;
		// background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		transform-origin: center center;
		transition: animation .2s ease-in-out opacity .2s ease-in-out;
		flex: 1;

		&.animate{
			animation-iteration-count: 1;
		}

		@include breakpoint( $breakpoint_m ) {
			background-size: cover;
			margin: 0px;
			width: calc( 100% - 0px );
			height: 60vh;
			flex: 2;
		}
	}
	
}